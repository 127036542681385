import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AtlantisNgxAuthGuard, AtlantisNgxAuthInterceptor, AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ConfigApiService } from '../services/config-api-service';
import { CameraApiService } from '../services/camera-api-service';
import { ObjectsWebSocketService } from '../services/objects-websocket-service';
import { MonitorWebSocketService } from '@dotocean/monitor-ngx-services';
import { NVRApiService } from '../services/nvr-service';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { DEFAULT_MQTT_SERVICE_OPTIONS, MQTT_SERVICE_OPTIONS, MqttService } from '../services/mqtt-service';
import { RadarWebSocketService } from '../services/radar-websocket-service';
import { VIRTUALWORLD_API_BASE_URL, VirtualworldNgxObjectsSocketService } from '@dotocean/virtualworld-ngx-services';
import { CALYPSO_API_BASE_URL } from '@dotocean/calypso-ngx-services';
import { ToastService } from '../services/toast-service';
import { FleetManagerWebSocketService } from '../services/fleetmanager-websocket.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CerebroApiService } from '../services/cerebro-api-service';
import { ThemeService } from '../services/theme-service';
import { APP_CONFIG, AppConfig } from '../app.config';
import { AppconfigService } from '../services/appconfig.service';
import { CLOUD_API_BASE_URL } from '../services/apis/cloud.service';
import { provideDaterangepickerLocale } from 'ngx-daterangepicker-bootstrap';
import { FleetmanagerApiService } from '../services/fleetmanager-api';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { MapPopupService } from '../services/map-popup.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ObjectsCacheApiService } from '../services/objects-cache-api-service';
import { IRtcServiceOptions, DEFAULT_RTC_SERVICE_OPTIONS, RTC_SERVICE_OPTIONS } from '../services/webrtc-service';
import { ModulesService } from '../services/modules/modules.service';
import { ModuleMonitorService } from '../services/modules/module-monitor.service';

export function rtcServiceOptionsFactory(config: AppConfig): IRtcServiceOptions {
  return config.rtc ?? DEFAULT_RTC_SERVICE_OPTIONS;
}

export function mqttServiceOptionsFactory(config: AppConfig): IMqttServiceOptions {
  return {
    hostname: config.mqtt?.hostname ?? DEFAULT_MQTT_SERVICE_OPTIONS.hostname,
    port: config.mqtt?.port ?? DEFAULT_MQTT_SERVICE_OPTIONS.port,
    protocol: config.mqtt?.protocol ?? DEFAULT_MQTT_SERVICE_OPTIONS.protocol,
    username: config.mqtt?.username ?? DEFAULT_MQTT_SERVICE_OPTIONS.username,
    password: config.mqtt?.password ?? DEFAULT_MQTT_SERVICE_OPTIONS.password,
    keepalive: config.mqtt?.keepalive ?? DEFAULT_MQTT_SERVICE_OPTIONS.keepalive,
    connectOnCreate: config.mqtt?.connectOnCreate ?? DEFAULT_MQTT_SERVICE_OPTIONS.connectOnCreate,
  };
}

export function appInitializer(appconfigService: AppconfigService) {
  return async () => {
    await Promise.all([appconfigService.loadConfig()]);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_CONFIG,
      useFactory: (appconfigService: AppconfigService) => appconfigService.getConfig(),
      deps: [AppconfigService],
    },
    provideLottieOptions({ player: () => player }),
    provideCacheableAnimationLoader(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    AtlantisNgxAuthService,
    AtlantisNgxAuthGuard,
    ConfigApiService,
    CameraApiService,
    CerebroApiService,
    ObjectsCacheApiService,
    FleetmanagerApiService,
    ObjectsWebSocketService,
    MonitorWebSocketService,
    FleetManagerWebSocketService,
    NVRApiService,
    RadarWebSocketService,
    VirtualworldNgxObjectsSocketService,
    ThemeService,
    MapPopupService,
    ModuleMonitorService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AtlantisNgxAuthInterceptor,
      multi: true,
    },
    {
      provide: CALYPSO_API_BASE_URL,
      useFactory: (config: AppConfig) => config.endpoints.ayb,
      deps: [APP_CONFIG],
    },
    {
      provide: VIRTUALWORLD_API_BASE_URL,
      useFactory: (config: AppConfig) => config.endpoints.objects,
      deps: [APP_CONFIG],
    },
    { provide: CLOUD_API_BASE_URL, useFactory: (config: AppConfig) => config.endpoints.api, deps: [APP_CONFIG] },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AppconfigService],
    },
    {
      provide: MQTT_SERVICE_OPTIONS,
      useFactory: mqttServiceOptionsFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: RTC_SERVICE_OPTIONS,
      useFactory: rtcServiceOptionsFactory,
      deps: [APP_CONFIG],
    },
    importProvidersFrom(
      MqttModule.forRoot({
        hostname: '', // Placeholder since options are injected and used in the Connect
        port: 0,
        protocol: 'wss',
        username: '',
        password: '',
        connectOnCreate: false,
      })
    ),
    MqttService,
    ToastService,
    ModulesService,
    provideDaterangepickerLocale({
      separator: ' - ',
      applyLabel: 'Okay',
    }),
  ],
};
