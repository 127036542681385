import { Component, inject, Input } from '@angular/core';
import { MapOptionsService, VesselSourceTypeExtended } from '../services/map-options.service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { VesselSearchComponent } from '../../shared/vessel-search/vessel-search.component';
import { MapMeasuringService } from '../services/map-measuring.service';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MapLayerService } from '../../../services/map-layer.service';
import { MapOptionsPopupComponent } from './map-options-popup/map-options-popup.component';
import { MapOptionsSelectableItemComponent } from './map-options-selectable-item/map-options-selectable-item.component';

@Component({
  selector: 'app-map-options',
  standalone: true,
  imports: [
    FleetManagerComponents,
    VesselSearchComponent,
    NgbDropdownModule,
    MapOptionsPopupComponent,
    NgbAccordionModule,
    MapOptionsSelectableItemComponent,
  ],
  templateUrl: './map-options.component.html',
  styleUrl: './map-options.component.scss',
})
export class MapOptionsComponent {
  public readonly mapOptionsService = inject(MapOptionsService);
  public readonly mapLayerService = inject(MapLayerService);
  public readonly mapMeasuringService = inject(MapMeasuringService);

  @Input() public enableSearch = true;

  public allAreHidden(items: VesselSourceTypeExtended[]) {
    return items.every((item) => !item.isActive);
  }
}
