import { Injectable, OnDestroy, Signal, signal, WritableSignal } from '@angular/core';
import { DeviceStatus, DeviceStatusMessage, DeviceStatusType, MonitorWebSocket, MonitorWebSocketService } from '@dotocean/monitor-ngx-services';

export function getAggregatedStatus(status: DeviceStatus | undefined): DeviceStatusType | undefined {
  if (!status) return undefined;
  // Collect all device statuses in an array
  const statuses = [status.connection, status.cameras, status.software, status.pc];

  // 1. If at least one is Error → overall Error
  if (statuses.includes(DeviceStatusType.Error)) {
    return DeviceStatusType.Error;
  }

  // 2. Otherwise, if at least one is Warn → overall Warn
  if (statuses.includes(DeviceStatusType.Warn)) {
    return DeviceStatusType.Warn;
  }

  // 3. Otherwise, everything is Normal
  return DeviceStatusType.Normal;
}

@Injectable()
export class ModuleMonitorService implements OnDestroy {
  private monitorSocket?: MonitorWebSocket;

  private deviceStatusesSignal: WritableSignal<Map<string, DeviceStatus>> = signal(new Map<string, DeviceStatus>());

  // Expose a readonly signal for component use
  public get deviceStatuses(): Signal<Map<string, DeviceStatus>> {
    return this.deviceStatusesSignal.asReadonly();
  }

  constructor(private readonly monitorWebSocketService: MonitorWebSocketService) {}

  public connectNodeIds(nodeIds: string[]) {
    if (nodeIds.length <= 0) {
      this.disconnect();
      return;
    }

    if (!this.monitorSocket) {
      this.monitorSocket = this.monitorWebSocketService.createSocket(nodeIds);
      this.monitorSocket.DeviceStatusChanged.subscribe((message) => this.deviceStatusChanged(message));
      this.monitorSocket.connect();
      return;
    }

    this.monitorSocket.setNodes(nodeIds);
  }

  private deviceStatusChanged(message: DeviceStatusMessage) {
    const currentStatuses = this.deviceStatusesSignal();
    const updatedStatuses = new Map(currentStatuses);
    updatedStatuses.set(message.nodeId, message.deviceStatus);
    this.deviceStatusesSignal.set(updatedStatuses);
  }

  public disconnect() {
    if (this.monitorSocket) {
      this.monitorSocket.disconnect();
      this.monitorSocket = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.disconnect();
  }
}
