<div class="map-measuring">
  <div class="map-measuring--header">
    <h4 class="map-measuring--header-title d-flex">
      <div>Measuring tool</div>
      <i class="btn-close me-1 m-auto" (click)="mapMeasuringService.toggleMeasuringTool()"></i>
    </h4>
    <div class="map-measuring--header-help">
      <div>Single click: Create new line or adds point to line</div>
      <div>Double click: Ends current line</div>
      <div>Right click: Removes point (Only for active line)</div>
    </div>
  </div>

  <div class="map-measuring--content">
    <div class="map-measuring--speed">
      <h5 class="map-measuring--speed-title">
        <span>Speed (knots): {{ selectedSpeed() }}</span>
        @if(selectedSpeed() !== defaultSpeed){
        <button class="btn btn-outline-warning btn-sm" ngbTooltip="Reset speed" (click)="selectedSpeed.set(defaultSpeed)"><i class="fa-solid fa-arrow-rotate-left"></i></button>
        }
      </h5>
      <input
        class="map-measuring--speed-range input-no-focus"
        type="range"
        step="0.2"
        [min]="1"
        [max]="24"
        [ngModel]="selectedSpeed()"
        (ngModelChange)="selectedSpeed.set($event)"
      />
    </div>

    @for (point of mapMeasuringService.pointList; track $index) {
    <div class="map-measuring--content-item">
      <ngx-colors
        class="map-measuring--content-item-color ms-auto"
        style="opacity: 1"
        ngx-colors-trigger
        [(ngModel)]="point.color"
        (ngModelChange)="point.colorChanged()"
      ></ngx-colors>

      <div class="map-measuring--content-item-distance">{{ point.distance | number : "1.2-2" }} nm</div>

      <div class="map-measuring--content-item-total">{{ calculateTotalTime(point.distance, selectedSpeed()) }}</div>

      @if(point.markersUnableToRemove){
      <div class="map-measuring--content-item-delete" (click)="mapMeasuringService.removePoint(point)">
        <i class="fa-solid fa-trash-can"></i>
      </div>
      }@else{
      <div class="map-measuring--content-item-delete map-measuring--content-item-delete-empty"></div>
      }
    </div>
    }
  </div>
</div>
