<button
  class="btn btn-toggle d-inline-flex align-items-center rounded border-0 mt-2 collapsed"
  data-bs-toggle="collapse"
  attr.data-bs-target="#item-collapse-{{ moduleDefinition.title }}"
  aria-expanded="true"
  ngbTooltip="{{ moduleDefinition.title }}"
  [openDelay]="500"
>
  <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14l6-6-6-6" />
  </svg>

  @if(!isCollapsed){ {{ moduleDefinition.title }} ({{ modules.length }}) }@else{ {{ modules.length }} }
</button>
<div class="collapse show mt-1" id="item-collapse-{{ moduleDefinition.title }}">
  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
    @for (module of modules; track $index) {
    <li (click)="selectModule.emit(module)">
      <div class="list-item" [class.list-item-selected]="selectedModule?.id === module.id" ngbTooltip="{{ module.name }}" [openDelay]="300">
        @if(module.type !== ModuleTypeDto.AYB && !isCollapsed){
        <svg
          class="list-item--status"
          [class.fill-normal]="
            module.webrtcConnection?.videoStarted === true || module.radarSocket?.isConnected === true || module?.status === DeviceStatusType.Normal
          "
          [class.fill-warning]="module?.status === DeviceStatusType.Warn"
          [class.fill-danger]="module?.status === DeviceStatusType.Error"
          width="20"
          height="20"
        >
          <use href="bootstrap-icons.svg#circle-fill" />
        </svg>
        }

        <a class="list-item--link link-body-emphasis d-inline-flex text-decoration-none rounded prevent-select">
          <img
            themedImage
            [srcLight]="moduleDefinition.imageSrcLight"
            [srcDark]="moduleDefinition.imageSrcDark"
            [themeReverse]="selectedModule?.id === module.id"
            class="pe-none bi"
            [attr.width]="isCollapsed ? 25 : 20"
            [attr.height]="isCollapsed ? 25 : 20"
            alt=""
          />

          @if(!isCollapsed){
          <span class="text-truncate ms-2">
            {{ module.name }}
          </span>
          }
        </a>

        @if(!isCollapsed && selectedModule?.id === module.id){
        <svg
          ngbTooltip="Update module"
          (click)="editModule.emit(module)"
          style="cursor: pointer; margin-top: 5px; margin-right: 10px; float: right"
          class="bi"
          width="20"
          height="20"
        >
          <use href="bootstrap-icons.svg#gear" />
        </svg>
        }
      </div>
    </li>
    }
  </ul>
</div>
