import { Component, computed, effect, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { NotificationService } from '../../services/shared-service';
import moment from 'moment';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs';
import { VesselClient } from '@dotocean/calypso-ngx-services';
import { ThemeService } from '../../services/theme-service';
import { AssetsAddComponent } from './assets-add/assets-add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetListExtended, fromAssetList } from '../../models/asset-list-extended';
import { AssetClient, AssetTypeDto, ModuleTypeDto } from '../../services/apis/cloud.service';
import { ComponentHeaderComponent } from '../shared/component-header/component-header.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { validate as isValidUUID } from 'uuid';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { ModuleExtended } from '../../models/module-extended';
import { DeviceStatusType } from '@dotocean/monitor-ngx-services';
import { getAggregatedStatus, ModuleMonitorService } from '../../services/modules/module-monitor.service';

@UntilDestroy()
@Component({
  selector: 'app-asset-list',
  standalone: true,
  imports: [FleetManagerComponents, ComponentHeaderComponent],
  templateUrl: './assets.component.html',
  styleUrl: './assets.component.scss',
})
export class AssetListComponent implements OnInit, OnDestroy {
  public type: AssetTypeDto | undefined;
  public assets: WritableSignal<AssetListExtended[]> = signal([]);

  public isLoading = false;
  public query: WritableSignal<string> = signal('');

  public activeTheme = 'light';

  public AssetTypeDto = AssetTypeDto;
  public DeviceStatusType = DeviceStatusType;

  public isViewGridActive = true;

  public ownedAssetsStatuses = computed(() => this.assetsFilteredName().filter((e) => e.owned));
  public favoriteAssetsStatuses = computed(() => this.assetsFilteredName().filter((e) => !e.owned));

  public assetsFilteredName = computed(() => {
    return this.assets().filter((e) => !e.name || (e.name ?? '').toLocaleLowerCase().indexOf(this.query()) > -1);
  });

  constructor(
    private readonly assetsClient: AssetClient,
    private readonly vesselClient: VesselClient,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute,
    public readonly themeService: ThemeService,
    private readonly modalService: NgbModal,
    private readonly authService: AtlantisNgxAuthService,
    private readonly moduleMonitorService: ModuleMonitorService
  ) {
    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    effect(() => {
      const statusMap = this.moduleMonitorService.deviceStatuses();
      this.assets().forEach((asset) => {
        const statusModule = asset.modules?.find((m) => m.type === ModuleTypeDto.MONITOR);
        const status = statusMap.get(statusModule?.parsedConfig.guid);

        asset.status = getAggregatedStatus(status);
        asset.lastUpdated = !status?.timestamp ? 'N/A' : moment.utc(status?.timestamp).fromNow();
      });
    });
  }
  ngOnDestroy(): void {
    this.moduleMonitorService.disconnect();
  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.type = data['type'];

      this.loadAssets();
      this.notificationService.assetsChangedEvent$
        .pipe(
          untilDestroyed(this),
          tap(() => this.loadAssets())
        )
        .subscribe();
    });
  }

  public loadAssets() {
    this.isLoading = true;

    this.assetsClient
      .getAssets(this.type)
      .pipe(
        tap((result) => {
          this.assets.set(result.map((r) => fromAssetList(r)));

          this.setAssetsVesselInfo();
          this.setAssetsMonitor();
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private setAssetsVesselInfo() {
    for (const asset of this.assets()) {
      if (asset.modules) {
        //TODO future: This asset.Online and asset.Lastupdated should be filled in in the API! --> The API will need to connect to AYB and get its values
        for (const module of asset.modules) {
          this.setAssetVesselInfoAyb(asset, module);
        }
      }
    }
  }

  private setAssetsMonitor() {
    const nodeIds: string[] = [];

    this.assets().forEach((a) => {
      a.modules?.forEach((module) => {
        if (module.type !== ModuleTypeDto.MONITOR) return; //TODO: || !this.authService.HasApplicationAccess('MONITOR')
        nodeIds.push(module.parsedConfig.guid);
      });
    });

    this.moduleMonitorService.connectNodeIds(nodeIds);
  }

  private setAssetVesselInfoAyb(asset: AssetListExtended, module: ModuleExtended) {
    if (module.type !== ModuleTypeDto.AYB || !this.authService.HasApplicationAccess('AYB')) return;

    if (asset.owned && module.parsedConfig?.guid && isValidUUID(module.parsedConfig.guid)) {
      this.vesselClient
        .getVessel(module.parsedConfig.guid)
        .pipe(
          tap((info) => {
            asset.status = info.online ?? false ? DeviceStatusType.Normal : DeviceStatusType.Error;

            if (asset.status === DeviceStatusType.Normal) asset.lastUpdated = 'Online';
            else asset.lastUpdated = moment.utc(info.online_changed).fromNow();
          })
        )
        .subscribe();
    }
  }

  public filterAssets(ev: any): void {
    this.query.set(ev.toLocaleLowerCase());
    // this.ownedAssets = this.assets.filter((e) => (e.name ?? '').toLocaleLowerCase().indexOf(this.query) > -1 && e.owned);
    // this.favoriteAssets = this.assets.filter((e) => (e.name ?? '').toLocaleLowerCase().indexOf(this.query) > -1 && !e.owned);
  }

  public openModal(): void {
    const modalRef = this.modalService.open(AssetsAddComponent);
    modalRef.componentInstance.fixedAssetType = this.type;

    modalRef.closed.subscribe((result: boolean) => {
      if (result) {
        this.loadAssets();
      }
    });
  }
}
