import { effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { MapPopupService } from './map-popup.service';
import TrackSymbol from '@arl/leaflet-tracksymbol2';
import L, { CircleMarker } from 'leaflet';
import { MapTrackerData } from '../models/map-tracker-data';
import { MapService } from './map.service';
import { MapMeasuringService } from '../app/map/services/map-measuring.service';

@Injectable()
export class MapMarkerService {
  private readonly mapPopupService = inject(MapPopupService);
  private readonly mapMeasuringService = inject(MapMeasuringService);
  private readonly mapService = inject(MapService);

  private highlightCircle: L.Circle | undefined;
  public selectedMapTrackerData: WritableSignal<MapTrackerData | undefined> = signal(undefined);

  constructor() {
    effect(() => {
      if (this.mapService.mapLoaded()) {
        this.mapService.subscribeTo_Click(() => {
          this.selectedMapTrackerData.set(undefined);
        });

        this.mapService.Map?.on('zoomend', () => {
          if (this.highlightCircle) {
            this.showHighlightCircle(undefined);
          }
        });
      }
    });

    effect(() => {
      const selectedMapTracker = this.selectedMapTrackerData();
      if (selectedMapTracker) {
        this.showHighlightCircle(new L.LatLng(selectedMapTracker.lat, selectedMapTracker.lng));
        return;
      }
      this.removeHighlightCircle();
    });
  }

  private isCircleMarker(symbol: TrackSymbol | CircleMarker | undefined): symbol is CircleMarker {
    return symbol instanceof CircleMarker;
  }

  public updateTracksymbol(trackSymbol: TrackSymbol | CircleMarker, trackerData: MapTrackerData, popupHTML: string, showTooltip: boolean) {
    trackSymbol.setLatLng([trackerData.lat, trackerData.lng]);

    const trackSymbolIsCircleMarker = this.isCircleMarker(trackSymbol);

    if (!trackSymbolIsCircleMarker) {
      trackSymbol.setHeading((trackerData.course * Math.PI) / 180.0);
      trackSymbol.setCourse((trackerData.course * Math.PI) / 180.0);
      trackSymbol.setSpeed(trackerData.speed);
    }

    trackSymbol.getPopup()?.setContent(popupHTML);

    if (showTooltip && !trackSymbol.getTooltip()) {
      this.addTooltipToMarker(trackSymbol, trackerData);
    }
    if (!showTooltip && trackSymbol.getTooltip()) {
      trackSymbol.unbindTooltip();
    }

    this.updateSelectedMapTracker(trackerData);
  }

  public addTrackerSymbol(
    trackSymbol: TrackSymbol | CircleMarker | undefined,
    trackerData: MapTrackerData,
    layerGroup: L.LayerGroup<any>,
    trackerAdded: (ts: TrackSymbol | CircleMarker) => void,
    showTooltip = false
  ) {
    const popupHTML = this.mapPopupService.getObjectPopupHtml(trackerData);

    const isVesselMoving = trackerData.speed && trackerData.speed > 0.2;
    const trackSymbolIsCircleMarker = this.isCircleMarker(trackSymbol);

    if (trackSymbol && ((isVesselMoving && !trackSymbolIsCircleMarker) || (!isVesselMoving && trackSymbolIsCircleMarker))) {
      this.updateTracksymbol(trackSymbol, trackerData, popupHTML, showTooltip);
      return;
    }

    if (trackSymbol) {
      layerGroup.removeLayer(trackSymbol);
    }

    const popup = L.popup({
      closeButton: false,
      autoClose: false,
      offset: [0, -10],
    }).setContent(popupHTML);

    const newTracker = isVesselMoving
      ? new TrackSymbol([trackerData.lat, trackerData.lng], {
          fill: true,
          fillColor: trackerData.sourceType?.color ?? trackerData.color,
          weight: 1,
          fillOpacity: 1,
          heading: ((trackerData.heading ?? trackerData.trueHeading!) * Math.PI) / 180.0,
          course: (trackerData.course * Math.PI) / 180.0,
          speed: trackerData.speed,
          pane: 'vesselPane',
          interactive: !this.mapMeasuringService.measuringActive(),
        })
      : new L.CircleMarker([trackerData.lat, trackerData.lng], {
          fill: true,
          fillColor: trackerData.sourceType?.color ?? trackerData.color,
          fillOpacity: 1,
          radius: 5,
          weight: 1.5,
          interactive: !this.mapMeasuringService.measuringActive(),
        });

    newTracker.bindPopup(popup);
    if (showTooltip) {
      this.addTooltipToMarker(newTracker, trackerData);
    }

    newTracker.on('click', (e) => {
      newTracker.closePopup();
      trackerData.lat = e.latlng.lat;
      trackerData.lng = e.latlng.lng;
      this.selectedMapTrackerData.set(trackerData);
      L.DomEvent.stopPropagation(e);
    });

    newTracker.on('mouseover', () => {
      if (this.selectedMapTrackerData()?.mmsi != trackerData.mmsi) newTracker.openPopup();
    });
    newTracker.on('mouseout', () => newTracker.closePopup());

    layerGroup.addLayer(newTracker);
    trackerAdded(newTracker);
  }

  private addTooltipToMarker(marker: TrackSymbol | L.CircleMarker<any>, trackerData: MapTrackerData) {
    marker.bindTooltip('' + (trackerData.name ?? trackerData.imoNumber ?? trackerData.mmsi), {
      permanent: true,
      direction: 'top', // Positions tooltip above the marker
      offset: [0, -10], // Adjust the vertical offset to look nicely placed
      className: 'marker-vesselName-tooltip', // Add a custom class for styling if needed
    });
  }

  public addTrackerLine(
    trackLine: L.Polyline | undefined,
    trackerHistoryCoordinates: [number, number][],
    trackerLineAdded: (ts: L.Polyline) => void
  ) {
    if (!this.mapService.Map) return;

    if (!trackLine) {
      const newTrackLine = L.polyline(trackerHistoryCoordinates, {
        color: 'white',
        weight: 1,
        opacity: 0.8,
      }).addTo(this.mapService.Map);

      trackerLineAdded(newTrackLine);
      return;
    }

    trackLine.setLatLngs(trackerHistoryCoordinates);
  }

  public updateSelectedMapTracker(newTrackerData: MapTrackerData) {
    const currentMapTracker = this.selectedMapTrackerData();
    if (newTrackerData.mmsi === currentMapTracker?.mmsi) {
      Object.assign(currentMapTracker, newTrackerData);

      this.changeHighlightLocation(newTrackerData.lat, newTrackerData.lng);
    }
  }

  public changeHighlightLocation(lat: number, lng: number) {
    if (this.highlightCircle) {
      this.highlightCircle.setLatLng([lat, lng]);
    }
  }

  private showHighlightCircle(latLng: L.LatLng | undefined) {
    const currentLatLng = latLng ?? this.highlightCircle?.getLatLng();
    if (this.highlightCircle) this.removeHighlightCircle();

    if (!currentLatLng) return;

    // Create and add the highlight circle around the marker
    this.highlightCircle = L.circle(currentLatLng, {
      radius: this.getDynamicRadius(), // Adjust as needed for visual preference
      color: 'blue', // Set the color for the highlight circle
      weight: 3,
      fillOpacity: 0.1,
    }).addTo(this.mapService.Map!);
  }

  private removeHighlightCircle() {
    // Remove the highlight circle if it exists
    if (this.highlightCircle) {
      this.mapService.Map?.removeLayer(this.highlightCircle);
      this.highlightCircle = undefined;
    }
  }
  private getDynamicRadius(): number {
    // Define the radius adjustment based on zoom level
    const zoomLevel = this.mapService.Map?.getZoom() ?? 13; // Default to 13 if map or zoom is undefined

    // Example radius scaling: adjust the base radius as desired
    const baseRadius = 200; // Base radius at zoom level 13
    const scaleFactor = Math.pow(2, 13 - zoomLevel); // Adjust radius exponentially with zoom

    return baseRadius * scaleFactor;
  }
}
