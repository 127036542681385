import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetClient, CreateModuleRequest, ModuleTypeDto } from '../../../../services/apis/cloud.service';
import { ThemeService } from '../../../../services/theme-service';
import { ToastService } from '../../../../services/toast-service';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { ModuleExtended } from '../../../../models/module-extended';
import { AssetExtended } from '../../../../models/asset-extended';
import { finalize, tap } from 'rxjs';
import { VesselClient } from '@dotocean/calypso-ngx-services';
import { SourceClient } from '@dotocean/virtualworld-ngx-services';
import { CameraApiService } from '../../../../services/camera-api-service';
import { ModulesService } from '../../../../services/modules/modules.service';

export interface AssetsDetailAddModuleResult {
  type: 'add' | 'update' | 'remove';
  module: ModuleExtended;
}

@Component({
  selector: 'app-assets-detail-module-add',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './assets-detail-module-add.component.html',
  styleUrl: './assets-detail-module-add.component.scss',
})
export class AssetsDetailAddModuleComponent implements OnInit {
  @Input({ required: true }) public asset: AssetExtended;
  @Input({ required: true }) public isNew = false;
  @Input()
  public module: ModuleExtended = { selectedEventPage: 1 };

  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastService = inject(ToastService);
  private readonly assetClient = inject(AssetClient);
  public readonly themeService = inject(ThemeService);
  private readonly vesselClient = inject(VesselClient);
  private readonly sourceClient = inject(SourceClient);
  private readonly cameraService = inject(CameraApiService);
  public readonly modulesService = inject(ModulesService);

  public invalidGUID = false;
  public moduleFound = false;
  public guidLoading = false;

  public ModuleTypeDto = ModuleTypeDto;
  public isSaving = false;
  public showConfirm = false;

  public ngOnInit(): void {
    if (this.isNew)
      this.module = {
        selectedEventPage: 1,
        type: ModuleTypeDto.AYB,
        parsedConfig: {
          subModules: { core: true },
          source: 'WEBRTC',
        },
      };
  }

  public addModule() {
    if (!this.asset?.id) return;

    this.isSaving = true;

    this.module.config = JSON.stringify(this.module.parsedConfig);
    this.assetClient
      .createModule(this.asset.id, this.module as CreateModuleRequest)
      .pipe(
        tap((id) => {
          this.module.id = id;
          this.toastService.showToast('Module has been added.');
          this.activeModal.close({ type: 'add', module: this.module } as AssetsDetailAddModuleResult);
        }),
        finalize(() => (this.isSaving = false))
      )
      .subscribe();
  }

  public updateModule() {
    if (this.isNew) return;

    this.module.config = JSON.stringify(this.module.parsedConfig);
    this.updateModule$()
      .pipe(
        tap(() => {
          this.toastService.showToast('Module has been updated.');
          this.activeModal.close({ type: 'update', module: this.module } as AssetsDetailAddModuleResult);
        }),
        finalize(() => (this.isSaving = false))
      )
      .subscribe();
  }

  private updateModule$() {
    const patchOperations = [
      { op: 'replace', path: '/name', value: this.module.name },
      { op: 'replace', path: '/config', value: this.module.config },
    ];

    return this.assetClient.updateModule(this.asset.id!, this.module.id!, patchOperations);
  }

  public removeModule() {
    if (this.isNew) return;

    this.isSaving = true;
    this.showConfirm = false;

    this.assetClient
      .deleteModule(this.asset.id!, this.module.id!)
      .pipe(
        tap(() => {
          this.toastService.showToast('Module has been removed.');
          this.activeModal.close({ type: 'remove', module: this.module } as AssetsDetailAddModuleResult);
        }),
        finalize(() => (this.isSaving = false))
      )
      .subscribe();
  }

  public async checkGUID(ev: any) {
    this.invalidGUID = false;
    this.moduleFound = false;

    if (ev.target.value.length == 36) {
      this.guidLoading = true;

      try {
        if (!this.module.type) return;
        switch (this.module.type) {
          case ModuleTypeDto.AYB:
            this.vesselClient.getVessel(ev.target.value).subscribe((result) => {
              if (result != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
          case ModuleTypeDto.RADAR:
            this.sourceClient.getSources(undefined).subscribe((result) => {
              const radarInfo = result.find((e: any) => e.sid == ev.target.value);

              if (radarInfo != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
          case ModuleTypeDto.CAMERA:
            this.cameraService.getSingleCamera(ev.target.value).subscribe((result) => {
              if (result != null) this.moduleFound = true;
              else this.invalidGUID = true;
            });
            break;
        }
      } catch {
        this.invalidGUID = true;
      }

      this.guidLoading = false;
    }
  }
}
