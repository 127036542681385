<!-- <div *ngFor="let module of asset.modules">
    <div *ngIf="module.id === cameraModule.id"> -->
<div *ngIf="cameraModule.parsedConfig.source === 'WEBRTC'" class="video-container">
  <div class="card">
    <div class="card-header">
      {{ cameraModule.parsedConfig.id }}
      <button
        (click)="stopVideo()"
        *ngIf="cameraModule.webrtcConnection?.videoStarted"
        [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
        style="margin-left: 5px"
        type="button"
        class="btn btn-sm"
      >
        Stop Stream
      </button>
    </div>
    <div style="overflow: hidden; margin: 0; padding: 0" class="card-body">
      <div class="container-fluid text-center m-0 p-0">
        <div class="row">
          <div class="col-10 position-relative">
            <div class="ratio ratio-16x9">
              <video [id]="'videoElement' + cameraModule.id" controls autoplay class="w-100"></video>
            </div>
            <div
              *ngIf="cameraModule.webrtcConnection?.videoStarted && cameraModule.webrtcConnection?.isPTZ"
              class="zoomControl position-absolute"
              style="bottom: 10px; right: 10px"
            >
              <svg (click)="zoomIn()" fill="white" width="30" height="30">
                <use href="bootstrap-icons.svg#zoom-in" />
              </svg>
              <svg (click)="zoomOut()" class="mt-2" fill="white" width="30" height="30">
                <use href="bootstrap-icons.svg#zoom-out" />
              </svg>
            </div>
            <svg
              (click)="startVideo()"
              *ngIf="!cameraModule.webrtcConnection?.videoStarted && !cameraModule.webrtcConnection?.videoLoading"
              class="playButton position-absolute"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
            >
              <use href="bootstrap-icons.svg#play-fill" />
            </svg>
            <div *ngIf="cameraModule.webrtcConnection?.videoLoading" class="spinner-border videoLoader text-light" role="status"></div>
          </div>
          <div class="col-2 position-relative">
            <div *ngIf="cameraModule.webrtcConnection?.videoStarted && cameraModule.webrtcConnection?.presets.length > 0">
              <div class="dropdown mt-1">
                <button
                  [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Presets
                </button>
                <ul style="cursor: pointer" class="dropdown-menu">
                  <li *ngFor="let preset of cameraModule.webrtcConnection?.presets">
                    <a (click)="goToPreset(preset.name)" class="dropdown-item">{{ preset.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="cameraModule.webrtcConnection?.videoStarted && cameraModule.webrtcConnection?.tours.length > 0">
              <div class="dropdown mt-2">
                <button
                  [ngClass]="activeTheme === 'light' ? 'btn-primary' : 'btn-light'"
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tours
                </button>
                <ul style="cursor: pointer" class="dropdown-menu">
                  <li *ngFor="let tour of cameraModule.webrtcConnection?.tours">
                    <a [ngClass]="tour.action === 'start' ? 'active' : ''" (click)="activateTour(tour.name)" class="dropdown-item">
                      {{ tour.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <button (click)="stopTour()" type="button" class="btn btn-danger mt-2">Stop Tour</button>
            </div>
            <app-ngx-joystick
              *ngIf="cameraModule.webrtcConnection?.videoStarted && cameraModule.webrtcConnection?.isPTZ"
              class="joystick"
              #joystick
              (move)="joystickMove($event)"
              (end)="joystickEnd()"
              [options]="joystickOptions"
            ></app-ngx-joystick>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<iframe
  class="video-container"
  *ngIf="cameraModule.parsedConfig.source === 'NVR'"
  id="iframe"
  style="width: 100%; height: calc(100vh - 174px)"
  [src]="nvrURL"
></iframe>
