import { Component, effect, inject, signal } from '@angular/core';
import { MapMeasuringService } from '../services/map-measuring.service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { NgxColorsModule } from 'ngx-colors';

@Component({
  selector: 'app-map-measuring',
  standalone: true,
  imports: [FleetManagerComponents, NgxColorsModule],
  templateUrl: './map-measuring.component.html',
  styleUrl: './map-measuring.component.scss',
})
export class MapMeasuringComponent {
  public readonly mapMeasuringService = inject(MapMeasuringService);

  public defaultSpeed = 8;
  public selectedSpeed = signal(this.defaultSpeed);

  private measuringSpeedKey = 'map.measuring.speed';

  constructor() {
    const initialPageSize = localStorage.getItem(this.measuringSpeedKey);
    if (initialPageSize) this.selectedSpeed.set(+initialPageSize);

    effect(() => {
      localStorage.setItem(this.measuringSpeedKey, '' + this.selectedSpeed());
    });
  }

  public calculateTotalTime(distance: number, speed: number) {
    // Calculate time in hours
    const timeInHours = distance / speed;

    // Convert the time into days, hours, and minutes
    const days = Math.floor(timeInHours / 24); // Full days
    const hours = Math.floor(timeInHours % 24); // Remaining hours
    const minutes = Math.round((timeInHours % 1) * 60); // Remaining minutes

    // Build the formatted string
    let timeString = '';

    if (days > 0) {
      timeString += `${days} day${days > 1 ? 's' : ''}`;
    }

    if (hours > 0) {
      if (timeString) timeString += ', ';
      timeString += `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    if (minutes > 0) {
      if (timeString) timeString += ' and ';
      timeString += `${minutes} min.`;
    }

    return timeString;
  }
}
