import { Component } from '@angular/core';
import moment from 'moment';
import { ToastService } from '../../services/toast-service';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import { AlertClient, Alert, AlertList } from '../../services/apis/cloud.service';
import { ComponentHeaderComponent } from '../shared/component-header/component-header.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsCreateComponent } from './alerts-create/alerts-create.component';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [FleetManagerComponents, ComponentHeaderComponent],
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  //public modal: any;
  public alerts: AlertList[] = [];
  public moment = moment;
  public isLoading = false;

  constructor(private alertClient: AlertClient, private toastService: ToastService, private readonly modalService: NgbModal) {
    this.loadAlerts();
  }

  private loadAlerts() {
    this.isLoading = true;
    this.alertClient.getAlerts(undefined).subscribe((result) => {
      this.alerts = result;
      this.isLoading = false;
    });
  }

  public updateAlert(alert: Alert) {
    const patchDocument = [{ op: 'replace', path: '/enabled', value: alert.enabled }];

    this.alertClient.updateAlert(alert.id!, patchDocument).subscribe(() => {
      this.toastService.showToast('Alert has been updated.');
    });
  }

  public removeAlert(alert: AlertList) {
    this.alertClient.removeAlert(alert.id!).subscribe(() => {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
      this.toastService.showToast('Alert has been removed.');
    });
  }

  public openModal(): void {
    const modalRef = this.modalService.open(AlertsCreateComponent);
    modalRef.closed.subscribe((result: boolean) => {
      if (result) {
        this.loadAlerts();
      }
    });
  }
}
