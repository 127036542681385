import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { Alert, AlertClient, AlertSourceDto, AlertTypeDto, UpdateAlertRequest } from '../../../services/apis/cloud.service';
import { MapService } from '../../../services/map.service';
import { ThemeService } from '../../../services/theme-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast-service';
import L from 'leaflet';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';

@Component({
  selector: 'app-alerts-create',
  standalone: true,
  providers: [MapService],
  imports: [FleetManagerComponents],
  templateUrl: './alerts-create.component.html',
  styleUrl: './alerts-create.component.scss',
})
export class AlertsCreateComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile') inputFile: ElementRef;

  private readonly mapService = inject(MapService);
  private readonly alertClient = inject(AlertClient);
  private readonly themeService = inject(ThemeService);
  private readonly toastService = inject(ToastService);
  private readonly authService = inject(AtlantisNgxAuthService);
  public readonly activeModal = inject(NgbActiveModal);

  public AlertSourceDto = AlertSourceDto;
  public AlertTypeDto = AlertTypeDto;
  public currentStep = 1;
  public alert: Alert = {};
  public activeTheme = 'light';

  public ngOnInit(): void {
    this.themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });

    this.currentStep = 1;

    this.alert = {
      type: AlertTypeDto.IN_APP,
      groupId: this.authService.identity?.Application?.Groups[0] ?? 1,
      source: AlertSourceDto.ALL,
    };

    // this.cerebroService.groups$.subscribe((groups) => {
    //   this.groups = groups;
    //   this.reset();
    // });
  }

  private initMap(): void {
    if (this.mapService.Map != null) {
      setTimeout(() => this.mapService.Map?.invalidateSize(), 100);
    } else {
      this.mapService.initMap({ addObjectOverlay: true, showMeasurementTool: false }, 'alertMap');
      this.mapService.setDefaultView();
    }
  }

  public continue(): void {
    this.currentStep += 1;
    if (this.currentStep == 2) this.initMap();
  }

  public back(): void {
    this.currentStep -= 1;
    if (this.currentStep == 2) this.initMap();
  }

  public addAlert() {
    if (!this.alert) return;

    this.alertClient.createAlert(this.alert as UpdateAlertRequest).subscribe(() => {
      this.toastService.showToast('Alert has been added.');
      this.activeModal.close(true);
    });
  }

  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        const geoJSON = JSON.parse(result);

        const layer = L.geoJSON(geoJSON, { style: { fillColor: '#E1234A', color: '#E1234A', weight: 0.5 } });
        this.mapService.Map?.addLayer(layer);
        this.mapService.Map?.fitBounds(layer.getBounds());

        this.alert.polygon = JSON.stringify(geoJSON.features[0].geometry);
      };

      reader.readAsText(file);
    }
  }

  public ngOnDestroy() {
    if (this.mapService != null) this.mapService.Dispose();
  }
}
