import { Component, computed, inject, Input, signal } from '@angular/core';
import { FleetManagerComponents } from '../../../../../shared/fleetmanager-components.module';
import { ModuleMonitorService } from '../../../../../services/modules/module-monitor.service';
import { ModuleExtended } from '../../../../../models/module-extended';
import { DeviceStatusType } from '@dotocean/monitor-ngx-services';

@Component({
  selector: 'app-module-monitor',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './module-monitor.component.html',
  styleUrl: './module-monitor.component.scss',
})
export class ModuleMonitorComponent {
  public readonly moduleMonitorService = inject(ModuleMonitorService);

  public DeviceStatusType = DeviceStatusType;

  private readonly _monitorModuleSig = signal<ModuleExtended | undefined>(undefined);

  @Input()
  set monitorModule(value: ModuleExtended) {
    this._monitorModuleSig.set(value);
  }
  get monitorModule(): ModuleExtended | undefined {
    return this._monitorModuleSig();
  }

  public statusses = computed(() => {
    const module = this._monitorModuleSig(); // read the signal
    const deviceStatus = this.moduleMonitorService.deviceStatuses().get(module?.parsedConfig.guid ?? '');
    return [
      { title: 'Computing Device', status: deviceStatus?.pc, icon: 'fa-gears' },
      { title: 'Connection', status: deviceStatus?.connection, icon: 'fa-tower-broadcast' },
      { title: 'Cameras', status: deviceStatus?.cameras, icon: 'fa-camera' },
      { title: 'Software', status: deviceStatus?.software, icon: 'fa-laptop-code' },
    ];
  });
}
