export class dmsHelper {
  public static convertToDMS(coordinate: number, isLat: boolean): string {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutes = Math.floor((absolute - degrees) * 60);
    const seconds = ((absolute - degrees - minutes / 60) * 3600).toFixed(2);

    const direction = isLat ? (coordinate >= 0 ? 'N' : 'S') : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
  }
}
