<div class="assets-details--modules" [class.assets-details--modules-visible]="modulesVisible">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        <svg style="vertical-align: -6px" class="bi pe-none me-2" [attr.width]="modulesVisible ? 30 : 24" [attr.height]="modulesVisible ? 30 : 24">
          <use xlink:href="bootstrap-icons.svg#boxes"></use>
        </svg>

        @if(modulesVisible){
        <div class="card-title-value">Modules</div>
        }

        <svg class="assets-detail--modules-collapse bi" (click)="toggleModules()" style="margin-right: -10px; cursor: pointer" width="24" height="24">
          <use
            [attr.href]="modulesVisible ? 'bootstrap-icons.svg#chevron-bar-left' : 'bootstrap-icons.svg#chevron-bar-right'"
            href="bootstrap-icons.svg#chevron-bar-right"
          />
        </svg>
      </h5>
    </div>

    <ul class="list-unstyled ps-0 mb-0 ms-2" [class.text-center]="!modulesVisible">
      @for (moduleDefinition of modulesService.moduleDefinitions; track $index) {
      <li *ngIf="(asset.modules | enumFilter : moduleDefinition.type).length > 0" class="mb-0">
        <app-assets-detail-modules-typeditem
          [moduleDefinition]="moduleDefinition"
          [modules]="asset.modules | enumFilter : moduleDefinition.type"
          [selectedModule]="selectedModule"
          [isCollapsed]="!modulesVisible"
          (editModule)="openAddOreditModuleModal($event)"
          (selectModule)="selectModule.emit($event)"
        ></app-assets-detail-modules-typeditem>
      </li>
      }
    </ul>

    <button
      (click)="openAddOreditModuleModal(undefined)"
      type="button"
      class="btn m-2"
      [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-outline-primary' : 'btn-outline-light'"
    >
      <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#plus" />
      </svg>
    </button>
  </div>
</div>
