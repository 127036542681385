<div ngbDropdown #layerDrop="ngbDropdown" autoClose="outside" placement="left-top"
class="dropdown-large">
  <button
    type="button"
    class="dropdown-toggle-button btn border-0"
    id="dropdownConfig"
    ngbDropdownToggle
    [ngbTooltip]="name"
    placement="left"
    triggers="hover"
    [openDelay]="100"
  >
    <i class="fa-solid" [ngClass]="fontAwesomeIcon"></i>
    @if(badgeActive){
    <i class="custom-badge fa-solid fa-circle"></i>
    }
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownConfig">
    <div class="dropdown-menu--header prevent-select">
      <span>
        <i class="dropdown-menu--header-icon fa-solid" [ngClass]="fontAwesomeIcon"></i>
        {{ name }}
      </span>
      <i class="btn-close me-1 m-auto" (click)="layerDrop.close()"></i>
    </div>

    <div class="dropdown-menu--items">
      <ng-content></ng-content>
    </div>
  </div>
</div>
