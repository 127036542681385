import { Component, Input } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-map-options-popup',
  standalone: true,
  imports: [FleetManagerComponents, NgbDropdownModule],
  templateUrl: './map-options-popup.component.html',
  styleUrl: './map-options-popup.component.scss'
})
export class MapOptionsPopupComponent {

  @Input({required: true}) name: string;
  @Input({required: true}) fontAwesomeIcon: string;

  @Input() badgeActive = false;

}
