import { Inject, Injectable } from '@angular/core';
import { IMqttMessage, IMqttServiceOptions, MqttService as NgxMqttService } from 'ngx-mqtt';
import { Observable } from 'rxjs';

export const MQTT_SERVICE_OPTIONS = 'MQTT_SERVICE_OPTIONS';
export const DEFAULT_MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt1.dotocean.io',
  port: 443,
  protocol: 'wss',
  username: 'admin_user',
  password: 'GqMvLjMJVRXyZJmemylstp11',
  keepalive: 10,
  connectOnCreate: false,
};

@Injectable()
export class MqttService {
  private _mqttTopicPrefix: string = 'webrtc/conference/';

  public isConnected: boolean = false;

  constructor(private ngxMqttService: NgxMqttService, @Inject(MQTT_SERVICE_OPTIONS) private mqttOptions: IMqttServiceOptions) {
    this.ngxMqttService.onConnect.subscribe(() => {
      this.isConnected = true;
      console.log('Connected to MQTT broker');
    });
    this.ngxMqttService.onOffline.subscribe(() => {
      this.isConnected = false;
      console.log('MQTT broker is offline');
    });
    this.ngxMqttService.onClose.subscribe(() => {
      this.isConnected = false;
      console.log('MQTT broker has been closed');
    });
    this.ngxMqttService.onEnd.subscribe(() => {
      this.isConnected = false;
      console.log('MQTT broker has been ended');
    });
  }

  public connect(): void {
    this.ngxMqttService.connect(this.mqttOptions);
  }

  public subscribe(topic: string): Observable<IMqttMessage> {
    return this.ngxMqttService.observe(this._mqttTopicPrefix + topic);
  }

  public publish(topic: string, payload: any): void {
    if (typeof payload !== 'string') payload.role = 'receiver';
    this.ngxMqttService.unsafePublish(this._mqttTopicPrefix + topic, JSON.stringify(payload), { qos: 1, retain: false });
  }

  public disconnect(): void {
    this.ngxMqttService.disconnect();
  }
}
