import { Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { FleetManagerComponents } from '../../../../../shared/fleetmanager-components.module';
import { ModuleExtended } from '../../../../../models/module-extended';
import { ModuleTypeDto } from '../../../../../services/apis/cloud.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModuleDefinition } from '../../../../../services/modules/modules.service';
import { getAggregatedStatus, ModuleMonitorService } from '../../../../../services/modules/module-monitor.service';
import { DeviceStatusType } from '@dotocean/monitor-ngx-services';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-assets-detail-modules-typeditem',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './assets-detail-modules-typeditem.component.html',
  styleUrl: './assets-detail-modules-typeditem.component.scss',
})
export class AssetsDetailModulesTypeditemComponent {
  @Input({ required: true }) public moduleDefinition: ModuleDefinition;
  @Input({ required: true }) public modules: ModuleExtended[];
  @Input({ required: true }) selectedModule?: ModuleExtended;
  @Input({ required: true }) isCollapsed = false;

  @Output()
  public editModule = new EventEmitter<ModuleExtended>();
  @Output()
  public selectModule = new EventEmitter<ModuleExtended>();

  public ModuleTypeDto = ModuleTypeDto;
  public DeviceStatusType = DeviceStatusType;

  constructor(private readonly moduleMonitorService: ModuleMonitorService) {
    effect(() => {
      const statusMap = this.moduleMonitorService.deviceStatuses();
      this.modules
        .filter((m) => m.type === ModuleTypeDto.MONITOR)
        .forEach((module) => {
          const status = statusMap.get(module?.parsedConfig.guid);

          module.status = getAggregatedStatus(status);
          module.lastUpdated = !status?.timestamp ? 'N/A' : moment.utc(status?.timestamp).fromNow();
        });
    });
  }
}
