import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';

@Component({
  selector: 'app-map-options-selectable-item',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './map-options-selectable-item.component.html',
  styleUrl: './map-options-selectable-item.component.scss',
})
export class MapOptionsSelectableItemComponent {
  @Input({ required: true }) public value: string;
  @Input({ required: true }) public isActive: boolean;

  @Output() public toggleClicked = new EventEmitter();
}
