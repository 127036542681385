import { computed, effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ResData } from '../../../services/apis/cloud.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ObjectsCacheApiService } from '../../../services/objects-cache-api-service';
import { GetObjectsResponse, SourceTypeColor } from '@dotocean/virtualworld-ngx-services';

export interface VesselSourceTypeExtended extends SourceTypeColor {
  isActive: boolean;
}

export interface NavigationStatus {
  code: number;
  value: string;
  isActive: boolean;
}

@UntilDestroy()
@Injectable()
export class MapOptionsService {
  public readonly objectsCacheApiService = inject(ObjectsCacheApiService);

  public readonly NAVIGATION_STATUS_DICTIONARY: Record<number, string> = {
    0: 'Under way using engine',
    1: 'At anchor',
    2: 'Not under command',
    3: 'Restricted manoeuverability',
    4: 'Constrained by her draught',
    5: 'Moored',
    6: 'Aground',
    7: 'Engaged in Fishing',
    8: 'Under way sailing',
    9: 'Reserved for future amendment of Navigational Status for HSC',
    10: 'Reserved for future amendment of Navigational Status for WIG',
    11: 'Reserved for future use',
    12: 'Reserved for future use',
    13: 'Reserved for future use',
    14: 'AIS-SART is active',
    15: 'Unknown',
  };

  public vesselSearch: WritableSignal<ResData | undefined> = signal(undefined);
  public vesselSourceTypes: WritableSignal<VesselSourceTypeExtended[]> = signal([]);

  public groupedVesselSourceTypes = computed(() => {
    // Use a dictionary keyed by `sourceType.name`
    const byName: Record<string, VesselSourceTypeExtended[]> = {};

    for (const source of this.vesselSourceTypes()) {
      const key = source.name ?? 'Other';
      if (!byName[key]) {
        byName[key] = [];
      }
      byName[key].push(source);
    }

    // Convert the object back into an array of groups
    const groups: { name: string; items: VesselSourceTypeExtended[] }[] = Object.entries(byName).map(([name, items]) => ({
      name,
      items,
    }));

    // Sort the groups by group.name (ascending)
    groups.sort((a, b) => a.name.localeCompare(b.name));

    // (Optional) sort the items within each group, e.g., by shipType or by name
    for (const group of groups) {
      group.items.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
      // If you want to sort by `name`, do:
      // group.items.sort((a, b) => a.name.localeCompare(b.name));
    }

    return groups;
  });

  public vesselSourceTypesAllSelected = computed(() => {
    const sourceTypes = this.vesselSourceTypes(); // read the signal
    return sourceTypes.every((s) => s.isActive);
  });

  public vesselSourceTypesSelected = computed(() => {
    const sourceTypes = this.vesselSourceTypes(); // read the signal
    return sourceTypes.filter((s) => s.isActive);
  });

  public groupedVesselSourcesNotActiveCount = computed(() => {
    return this.groupedVesselSourceTypes().filter((s) => s.items.find((item) => !item.isActive) !== undefined).length;
  });

  public navigationStatusesNotActiveCount = computed(() => {
    return this.relevantNavigationStatuses().filter((l) => !l.isActive).length;
  });

  public filtersActiveCount = computed(() => {
    return this.groupedVesselSourcesNotActiveCount() + this.navigationStatusesNotActiveCount();
  });

  public readonly objectsSignal = signal<GetObjectsResponse[]>([]);

  public readonly navStatusSignal = signal<NavigationStatus[]>(
    Object.entries(this.NAVIGATION_STATUS_DICTIONARY).map(([key, value]) => ({
      code: Number(key),
      value,
      isActive: true,
    }))
  );
  // Distinct numeric statuses actually found in objectsSignal
  private distinctNavigationStatusCodes = computed(() => {
    const codes = new Set(
      this.objectsSignal()
        .map((item) => item.navigation_status ?? 15) //15 = Undefined (default) https://documentation.spire.com/ais-fundamentals/how-to-interpret-navigational-status-codes/
    );
    return [...codes]; // convert Set -> array
  });

  // Build a dictionary of those codes -> label
  public readonly relevantNavigationStatuses = computed(() => {
    const codesInData = this.distinctNavigationStatusCodes();
    // The navStatusSignal stays in the other service, but we can read it:
    const fullList = this.navStatusSignal();
    return fullList.filter((status) => codesInData.includes(status.code));
  });

  public navigationStatusesAllSelected = computed(() => {
    const sourceTypes = this.relevantNavigationStatuses(); // read the signal
    return sourceTypes.every((s) => s.isActive);
  });

  public navigationStatusesSelected = computed(() => {
    const sourceTypes = this.relevantNavigationStatuses(); // read the signal
    return sourceTypes.filter((s) => s.isActive);
  });

  private readonly navStatussesLocalStorageKey = 'map.navigationStatusCodesExcluded';
  private readonly vesselSourceTypesLocalStorageKey = 'map.vesselSourceTypeNamesExcluded';

  constructor() {
    this.readLocalStorageAndSetNavStatusAndVesselSourceTypes();

    effect(() => {
      const navStatusSignalCodes = this.navStatusSignal()
        .filter((l) => !l.isActive)
        .map((l) => l.code);
      //Save the navStatus to the local storage
      localStorage.setItem(this.navStatussesLocalStorageKey, JSON.stringify(navStatusSignalCodes));
    });

    effect(() => {
      const vesselSourceTypeNames = this.vesselSourceTypes()
        .filter((l) => !l.isActive)
        .map((l) => l.name);
      //Save the navStatus to the local storage
      localStorage.setItem(this.vesselSourceTypesLocalStorageKey, JSON.stringify(vesselSourceTypeNames));
    });
  }

  private readLocalStorageAndSetNavStatusAndVesselSourceTypes() {
    // 1. First, read the local storage for NavigationStatus codes that should be inactive
    const storedNavStatusCodes = localStorage.getItem(this.navStatussesLocalStorageKey);
    if (storedNavStatusCodes) {
      const notActiveCodes = JSON.parse(storedNavStatusCodes) as number[];
      // Update the signal: set `isActive = false` for codes in `notActiveCodes`
      this.navStatusSignal.update((currentNavStatuses) =>
        currentNavStatuses.map((nav) => ({
          ...nav,
          isActive: !notActiveCodes.includes(nav.code),
        }))
      );
    }
    const storedvesselSourceTypes = localStorage.getItem(this.vesselSourceTypesLocalStorageKey);
    const storedvesselSourceTypesConverted = storedvesselSourceTypes ? (JSON.parse(storedvesselSourceTypes) as string[]) : [];
    this.objectsCacheApiService.SourceTypeColor$.subscribe((sourceTypeColor) => {
      this.vesselSourceTypes.set(
        sourceTypeColor.map((s) => ({ ...s, isActive: !storedvesselSourceTypesConverted.includes(s.name ?? '') } as VesselSourceTypeExtended))
      );
    });
  }

  public toggleVesselSource(vesselSource: VesselSourceTypeExtended) {
    this.vesselSourceTypes.update((prevList) => {
      return prevList.map((v) =>
        v.sourceType === vesselSource.sourceType && v.shipType === vesselSource.shipType ? { ...v, isActive: !v.isActive } : v
      );
    });
  }

  public toggleVesselSourceByName(vesselSourceName: string, isActive: boolean) {
    this.vesselSourceTypes.update((prevList) => {
      return prevList.map((v) => (v.name === vesselSourceName ? { ...v, isActive: !isActive } : v));
    });
  }

  public refreshNavigationStatusses(objects: GetObjectsResponse[]) {
    this.objectsSignal.set(objects);
  }

  public toggleNavigationStatus(code: number) {
    this.navStatusSignal.update((statusArray) => statusArray.map((item) => (item.code === code ? { ...item, isActive: !item.isActive } : item)));
  }

  public setNavigationStatusAll(isActive: boolean) {
    this.navStatusSignal.update((statusArray) => statusArray.map((item) => ({ ...item, isActive: isActive })));
  }
  public setVesselSourceTypesAll(isActive: boolean) {
    this.vesselSourceTypes.update((vstList) => vstList.map((item) => ({ ...item, isActive: isActive })));
  }
}
