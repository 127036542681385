<div class="monitor-status row">
  @for (status of statusses(); track $index) {
  <div class="col-3">
    <div class="monitor-status-item">
      <div class="monitor-status-item--group">
        <i icon class="monitor-status-item--icon fa-solid" [ngClass]="status.icon"></i>
        <div class="monitor-status-item--title">{{ status.title }}</div>
      </div>
      <div class="monitor-status-item--status">
        <svg
          [ngClass]="status?.status?.toString()?.toLowerCase()"
          [class.fill-normal]="status?.status === DeviceStatusType.Normal"
          [class.fill-warning]="status?.status === DeviceStatusType.Warn"
          [class.fill-danger]="status?.status === DeviceStatusType.Error"
          width="30"
          height="30"
        >
          <use href="bootstrap-icons.svg#circle-fill" />
        </svg>
      </div>
    </div>
  </div>
  }
</div>
