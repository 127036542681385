<div class="card mb-2 border-0 border-bottom rounded-0">
  <div class="card-body">
    <div class="row justify-content-between align-items-center flex-grow-1f">
      <div class="col-md">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="card-header-title">{{ title }}</h4>
        </div>
      </div>
      <div class="col-auto">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </div>
      <div class="col-auto">
        <div class="row align-items-sm-center">
          <div class="col-auto" *ngIf="enableSearching">
            <form role="search">
              <input
                [(ngModel)]="searchValue"
                (ngModelChange)="this.searchValueUpdate.next($event)"
                name="search"
                type="search"
                class="form-control"
                placeholder="Search..."
                aria-label="Search"
              />
            </form>
          </div>
          <div class="col-auto component-header--filter-dropdown" *ngIf="activeFilters !== undefined">
            <div ngbDropdown class="d-inline-block" #dropdown="ngbDropdown" autoClose="outside" (openChange)="checkDropDown()">
              <button
                type="button"
                [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-outline-primary' : 'btn-outline-light'"
                class="btn"
                id="filterDropdown"
                ngbDropdownToggle
              >
                <i class="fa-solid fa-filter"></i>
                Filters
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="activeFilters > 0">
                  {{ activeFilters }}
                </span>
              </button>
              <div ngbDropdownMenu aria-labelledby="filterDropdown">
                <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
                <div class="d-flex component">
                  <button
                    (click)="close();resetFilter.emit()"
                    type="button"
                    [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-outline-dark' : 'btn-dark'"
                    class="p-2 flex-fill btn m-2"
                  >
                    Reset
                  </button>
                  <button
                    (click)="close();applyFilter.emit()"
                    type="button"
                    [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'"
                    class="btn p-2 flex-fill m-2"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <svg *ngIf="enableViewToggling" (click)="toggleActiveView()" style="vertical-align: -9px; cursor: pointer" class="bi me-4" width="24" height="24">
              <use *ngIf="isViewGridActive" href="bootstrap-icons.svg#list-ul" />
              <use *ngIf="!isViewGridActive" href="bootstrap-icons.svg#grid" />
            </svg>

            <button
              *ngIf="newButtonText"
              [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'"
              type="button"
              class="btn"
              (click)="addNewClicked.emit()"
            >
              <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
                <use href="bootstrap-icons.svg#plus" />
              </svg>
              {{ newButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Define templates for named projections -->
<ng-template #filtersTemplate>
  <div class="component-header--filters">
    <ng-content select="[filters]"></ng-content>
  </div>
</ng-template>


<!-- Define templates for named projections -->
<ng-template #actionsTemplate>
  <div class="component-header--actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</ng-template>
