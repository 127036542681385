import { effect, inject, Injectable, signal } from '@angular/core';
import { MapService } from '../../../services/map.service';
import L from 'leaflet';
import { ToastService } from '../../../services/toast-service';
import { MapLayerService } from '../../../services/map-layer.service';

export class MeasuringPoint {
  constructor(color: string) {
    this.color = color;
  }

  private proximityThreshold: number = 10; // Proximity threshold in pixels

  line?: L.Polyline;
  points: L.LatLng[] = [];
  distance: number = 0;
  color: string;

  markers: L.CircleMarker[] = [];

  public markersUnableToRemove = false;

  public removeContextMenu() {
    this.markersUnableToRemove = true;
  }

  public colorChanged() {
    this.line?.setStyle({ color: this.color });
    this.markers.forEach((m) => {
      m.setStyle({ color: this.color, fillColor: this.color });
    });
  }

  public addNewPoint(map: L.Map, group: L.LayerGroup, latlng: L.LatLng) {
    // Check if the clicked point is near an existing point
    if (this.isNearExistingPoint(map, latlng)) {
      return; // Ignore click if it's near an existing point
    }

    if (this.points.length > 0) {
      const lastpoint = this.points.at(-1);
      if (lastpoint) {
        this.distance += map.distance(lastpoint, latlng) / 1852; // Distance in nm;
      }
    }

    // Add the clicked point to the current points array
    this.points.push(latlng);

    // Add a marker at the clicked point
    const marker = L.circleMarker(latlng, {
      radius: 6,
      color: this.color,
      fillColor: this.color,
      fillOpacity: 0.5,
    }).addTo(group);

    marker.on('contextmenu', () => this.removePoint(map, group, marker, latlng));

    this.markers.push(marker);

    // Add a tooltip to show the distance
    this.setTooltipFormarker(marker);

    // If it's the first point, create a new polyline
    if (!this.line) {
      this.line = L.polyline([latlng], { color: this.color }).addTo(group);
    } else if (this.line) {
      // Add the new point to the existing polyline
      this.line.addLatLng(latlng);
    }
  }

  private removePoint(map: L.Map, group: L.LayerGroup, marker: L.CircleMarker, latlng: L.LatLng): void {
    if (this.markersUnableToRemove || this.points.length < 2) return;

    this.points = this.points.filter((obj) => obj.lat !== latlng.lat || obj.lng !== latlng.lng);
    this.line?.setLatLngs(this.points);

    group.removeLayer(marker);
    this.markers = this.markers.filter((obj) => obj !== marker);

    this.calculateNewPointDistances(map);
  }

  private setTooltipFormarker(marker: L.CircleMarker) {
    if (this.markers.indexOf(marker) === 0) {
      marker.unbindTooltip();
      return;
    }

    marker.bindTooltip(`${this.distance.toFixed(2)} nm`, {
      permanent: true,
      direction: 'top',
      offset: [0, -10],
      className: 'map-measuring--tooltip',
    });
  }

  private calculateNewPointDistances(map: L.Map) {
    let lastpoint: L.LatLng | undefined = undefined;
    this.distance = 0;

    this.markers.forEach((m) => {
      const latlng = m.getLatLng();

      if (lastpoint) {
        this.distance += map.distance(lastpoint, latlng) / 1852; // Distance in nm;
      }

      this.setTooltipFormarker(m);

      lastpoint = latlng;
    });
  }

  private isNearExistingPoint(map: L.Map, latlng: L.LatLng): boolean {
    const mapPoint = map.latLngToContainerPoint(latlng); // Convert to container point (pixels)

    // Check proximity to all existing points
    for (const point of this.points) {
      const existingPoint = map.latLngToContainerPoint(point);
      const distance = mapPoint.distanceTo(existingPoint);
      if (distance < this.proximityThreshold) {
        return true; // Point is near an existing point
      }
    }

    return false; // Point is not near any existing point
  }
}
@Injectable({
  providedIn: 'root',
})
export class MapMeasuringService {
  private readonly mapService = inject(MapService);
  private readonly mapLayerService = inject(MapLayerService);
  private readonly toastService = inject(ToastService);

  public measuringMapGroup?: L.LayerGroup;

  private currentPoint?: MeasuringPoint;

  public pointList: MeasuringPoint[] = [];

  private predefinedColors: string[] = ['blue', 'green', 'red', 'orange', 'purple']; // List of colors

  public measuringActive = signal(false);

  constructor() {
    effect(() => {
      if (this.measuringActive()) {
        this.enableDrawing();
        return;
      }

      this.disableDrawingMode();
    });
  }

  public toggleMeasuringTool() {
    if (!this.mapService.Map) return;
    this.measuringActive.set(!this.measuringActive());
  }

  private enableDrawing() {
    this.disableDrawingMode();

    if (!this.mapService.Map) return;
    this.mapLayerService.toggleInteractiveOnMarkersOnObjects(false);
    this.mapService.Map.doubleClickZoom.disable();

    this.measuringMapGroup = new L.LayerGroup().addTo(this.mapService.Map!);
    this.measuringMapGroup.setZIndex(888);

    this.mapService.Map.on('click', this.addPoint.bind(this));
    this.mapService.Map.on('dblclick', this.completeDrawing.bind(this));
  }

  public removePoint(point: MeasuringPoint) {
    point.markers.forEach((m) => this.measuringMapGroup?.removeLayer(m));
    if (point.line) this.measuringMapGroup?.removeLayer(point.line);
    this.pointList = this.pointList.filter((p) => p !== point);
  }

  addPoint(event: L.LeafletMouseEvent): void {
    if (!this.mapService.Map || !this.measuringMapGroup) return;

    if (!this.currentPoint) {
      if (this.pointList.length >= 5) {
        this.toastService.showWarning('Maximum number (5) of drawings reached.');
        return;
      }

      const colorForPoint = this.getCurrentColor();
      if (!colorForPoint) {
        this.toastService.showWarning('Maximum number of drawings reached.');
        return;
      }

      this.currentPoint = new MeasuringPoint(colorForPoint);
      this.pointList = [...this.pointList, this.currentPoint];
    }

    // Calculate the distance from the last point
    this.currentPoint.addNewPoint(this.mapService.Map, this.measuringMapGroup, event.latlng);
  }

  completeDrawing(): void {
    if (!this.currentPoint) return;

    if (this.currentPoint.points.length < 2) {
      this.toastService.showWarning('Add at least 2 points');
      return;
    }

    this.currentPoint.removeContextMenu();

    //Restart a new point
    this.currentPoint = undefined;
  }

  disableDrawingMode(): void {
    // Clear all drawings from the map
    if (this.measuringMapGroup) {
      this.measuringMapGroup.clearLayers();
      this.mapService.removeLayer(this.measuringMapGroup);
    }

    this.currentPoint = undefined;
    this.pointList = [];
    if (this.mapService.Map) {
      this.mapService.Map.doubleClickZoom.enable();
      this.mapLayerService.toggleInteractiveOnMarkersOnObjects(true);
      this.mapService.Map.off('click', this.addPoint.bind(this));
      this.mapService.Map.off('dblclick', this.completeDrawing.bind(this));
    }
  }

  private getCurrentColor(): string {
    // Get the set of colors that are already used
    const usedColors = new Set(this.pointList.map((point) => point.color));

    // Return the first color from predefinedColors that hasn't been used
    for (let color of this.predefinedColors) {
      if (!usedColors.has(color)) {
        return color;
      }
    }

    return 'black'; // or some other default color
  }
}
