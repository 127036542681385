import { Inject, Injectable } from '@angular/core';
import { WebRTCConnection } from '../models/webRTCConnection';
import { MqttService } from './mqtt-service';

export const RTC_SERVICE_OPTIONS = 'RTC_SERVICE_OPTIONS';
export interface IRtcServiceOptions {
  iceServers: RTCIceServer[];
}

export const DEFAULT_RTC_SERVICE_OPTIONS: IRtcServiceOptions = {
  iceServers: [
    { urls: 'stun:turn1.dotocean.io:3478' },
    {
      urls: 'turn:turn1.dotocean.io:3478',
      username: 'turn',
      credential: 'sxStW5tfXXcBqwtJn23PNEMp',
    },
  ],
};

@Injectable()
export class WebrtcService {
  private connections = new Map<string, WebRTCConnection>();

  constructor(private mqttService: MqttService, @Inject(RTC_SERVICE_OPTIONS) private rtcOptions: IRtcServiceOptions) {}

  public startConnection(connectionId: string, videoElement: HTMLVideoElement): WebRTCConnection {
    const connection = new WebRTCConnection(this.mqttService, connectionId, videoElement, this.rtcOptions);
    this.connections.set(connectionId, connection);

    this.mqttService.subscribe(connectionId).subscribe((message) => connection.handleMqttMessage(message));
    this.mqttService.publish(connectionId, 'yello');

    return connection;
  }

  public stopConnection(connectionId: string): void {
    const connection = this.connections.get(connectionId);
    if (connection) {
      connection.disconnect();
      this.connections.delete(connectionId);
    }
  }
}
