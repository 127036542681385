import { Injectable } from '@angular/core';
import { ModuleTypeDto } from '../apis/cloud.service';

export interface SubModuleDefinition {
  name: string;
  title: string;
  features: string[];
  isCore: boolean;
  isEnabled: (subModule: any) => boolean;
}

export interface ModuleDefinition {
  type: ModuleTypeDto;
  imageSrcLight: string;
  imageSrcDark: string;
  title: string;
  defaultTab: string;
  subModules: SubModuleDefinition[];
}

export interface ModuleTabsDefinition {
  name: string;
  title: string;
  allowedModuleTypes?: ModuleTypeDto[];
  allowedForGroups?: number[];
}

const moduleTabs: ModuleTabsDefinition[] = [
  {
    name: 'livestream',
    title: 'Live stream',
    allowedModuleTypes: [ModuleTypeDto.CAMERA],
  },
  {
    name: 'images',
    title: 'Images',
    allowedModuleTypes: [ModuleTypeDto.RADAR],
  },
  {
    name: 'status',
    title: 'Status',
    allowedModuleTypes: [ModuleTypeDto.MONITOR],
  },
  {
    name: 'submodules',
    title: 'Submodules',
    allowedForGroups: [2],
  },
];

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  public moduleTabs: readonly ModuleTabsDefinition[] = moduleTabs;

  public moduleDefinitions: ModuleDefinition[] = [
    {
      type: ModuleTypeDto.AYB,
      imageSrcLight: 'icons/joystick-ayb-dark.svg',
      imageSrcDark: 'icons/joystick-ayb-white.svg',
      title: 'AYB',
      defaultTab: 'submodules',
      subModules: [
        {
          isCore: true,
          name: 'core',
          title: 'Core',
          features: ['Navigate autonomously', 'Give tasks to control your vessel', 'Cloud connected'],
          isEnabled: () => true,
        },
        {
          isCore: false,
          name: 'vw',
          title: 'Virtual World',
          features: ['Build virtual navigation maps', 'Based on actual geo data', 'Path planning capabilities'],
          isEnabled: (subModules) => subModules.vw,
        },
      ],
    },
    {
      type: ModuleTypeDto.CAMERA,
      imageSrcLight: 'icons/visor-dark.svg',
      imageSrcDark: 'icons/visor-white.svg',
      title: 'Camera',
      defaultTab: 'livestream',
      subModules: [
        { isCore: true, name: 'core', title: 'Core', features: ['Records video', 'View saved video', 'Livestream'], isEnabled: () => true },
        {
          isCore: false,
          name: 'recordings',
          title: 'Recordings',
          features: ['Activity recognition', 'Motion detection', 'Provides remote access'],
          isEnabled: (subModules) => subModules.recordings,
        },
      ],
    },
    {
      type: ModuleTypeDto.RADAR,
      imageSrcLight: 'icons/radar-dark.svg',
      imageSrcDark: 'icons/radar-white.svg',
      title: 'Radar',
      defaultTab: 'images',
      subModules: [
        { isCore: true, name: 'core', title: 'Core', features: ['Detect objects', 'Determine distance', 'Tracks objects'], isEnabled: () => true },
        {
          isCore: false,
          name: 'drot',
          title: 'DROT',
          features: ['Dynamic object detection/tracking', 'Distribute detection/tracks', 'to other networks'],
          isEnabled: (subModules) => subModules.drot,
        },
      ],
    },
    {
      type: ModuleTypeDto.MONITOR,
      imageSrcLight: 'icons/monitor-dark.svg',
      imageSrcDark: 'icons/monitor-light.svg',
      title: 'Monitor',
      defaultTab: 'status',
      subModules: [{ isCore: true, name: 'core', title: 'Core', features: ['Monitor devices', 'View different statusses'], isEnabled: () => true }],
    },
  ];

  public getModuleDefinitionFromType(type: ModuleTypeDto) {
    return this.moduleDefinitions.find((m) => m.type === type);
  }

  public getTabsForModuleType(moduleType: ModuleTypeDto | undefined, companyId: number | undefined): ModuleTabsDefinition[] {
    if (!moduleType) return [];

    return this.moduleTabs.filter(
      (mt) =>
        moduleType &&
        (!mt.allowedModuleTypes || mt.allowedModuleTypes.includes(moduleType)) &&
        (!mt.allowedForGroups || mt.allowedForGroups.includes(companyId ?? -1))
    );
  }
}
