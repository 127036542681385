import { Component, Input } from '@angular/core';
import { LoadingDotComponent } from '../loading-dot/loading-dot.component';

@Component({
  selector: 'app-loading-popup',
  standalone: true,
  imports: [LoadingDotComponent],
  templateUrl: './loading-popup.component.html',
  styleUrl: './loading-popup.component.scss',
})
export class LoadingPopupComponent {
  @Input() content?: string;
}
