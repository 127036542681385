import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { Region, RegionSmall } from '../../../services/apis/cloud.service';
import { MapService } from '../../../services/map.service';
import { filter, tap } from 'rxjs';
import L, { PathOptions } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';

export function regionIsPolygon(region: RegionSmall | Region) {
  return region.polygons && region.polygons.length > 0;
}

export function getRegionLeafletObject(region: RegionSmall | Region, outlineOnly = false) {
  const style = outlineOnly
    ? ({ color: '#f03', fill: false, opacity: 0.5, stroke: true, pane: 'overlayPane', interactive: false } as PathOptions)
    : ({ color: 'red', fillColor: '#f03', fillOpacity: 0.5, stroke: false, pane: 'overlayPane', interactive: false } as PathOptions);

  if (regionIsPolygon(region)) {
    return L.polygon(
      region.polygons!.map((p) => new L.LatLng(p.latitude!, p.longitude!)),
      style
    );
  }

  if (!region.latitude || !region.longitude) return undefined;

  // Draw a circle (radius) on the map
  return L.circle([region.latitude, region.longitude], { ...style, radius: region.radius! * 1000 });
}

@Component({
  selector: 'app-region-map',
  standalone: true,
  imports: [FleetManagerComponents],
  providers: [MapService],
  templateUrl: './region-map.component.html',
  styleUrl: './region-map.component.scss',
})
export class RegionMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) public region!: RegionSmall;

  private regionRadius: L.Circle | L.Polygon | undefined;

  private readonly mapService = inject(MapService);

  public regionMapId = 'regionmap';

  public ngOnInit() {
    this.regionMapId = 'regionmap' + this.region.id! + uuidv4();

    this.mapService.mapLoaded$
      .pipe(
        filter((loaded) => loaded),
        tap(() => {
          if (!this.mapService.Map) return;

          //Moved here so we set map stuff only when the map was loaded
          this.mapService.setBounds();

          this.drawPolygon();

          if (!this.regionRadius) return;

          const bounds = this.regionRadius.getBounds();
          // Adjust the map view to fit the bounds
          this.mapService.Map.fitBounds(bounds, { padding: [15, 15] });
        })
      )
      .subscribe();
  }

  private drawPolygon() {
    if (!this.mapService.Map) return;

    this.regionRadius = getRegionLeafletObject(this.region)?.addTo(this.mapService.Map);
  }

  public async ngAfterViewInit() {
    if (!this.mapService.Map)
      await this.mapService.initMap(
        {
          addObjectOverlay: false,
          showLayerControl: false,
          showScale: false,
          showMeasurementTool: false,
        },
        this.regionMapId
      );
  }

  public ngOnDestroy(): void {
    // Dispose of the circle if it exists
    if (this.regionRadius) {
      this.mapService.Map?.removeLayer(this.regionRadius);
    }

    // Dispose of the map
    if (this.mapService.Map) {
      this.mapService.Dispose();
    }
  }
}
