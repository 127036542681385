import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { RadarImage } from '../models/radar-image';

@Injectable()
export class RadarWebSocketService implements OnDestroy {
  public url = 'https://api.dotocean.eu/radar/';
  private _socket: RadarWebSocket;

  constructor(private authService: AtlantisNgxAuthService) {}

  public createRadarSocket(radarId: any): RadarWebSocket {
    this._socket = new RadarWebSocket(this.url, this.authService, radarId);
    return this._socket;
  }

  ngOnDestroy(): void {
    if (this._socket != null) this._socket.disconnect();
  }
}

export class RadarWebSocket {
  public url: string;
  public ImageChanged: Subject<RadarImage> = new Subject<RadarImage>();

  public isConnected?: boolean;
  private _isDisconnecting?: boolean;
  private _connectionTimeout = 3000;
  private _socket: WebSocket;
  private _keepAlive: any;

  constructor(public rooturl: string, private authService: AtlantisNgxAuthService, public radarId: any) {}

  public connect() {
    this.reconnect();
  }

  private reconnect(): void {

    if (!this.authService.identity?.Session.SessionToken) {
      setTimeout(() => this.reconnect(), this._connectionTimeout);
      return;
    }

    const rooturlFormat = this.rooturl.endsWith('/') ? this.rooturl : this.rooturl + '/';

    this.url =
      rooturlFormat.replace('http', 'ws') +
      `radar/${this.radarId}/socket?session=${encodeURIComponent(this.authService.identity?.Session.SessionToken)}`;
    console.log(`Connecting to radar socket`);

    this._socket = new WebSocket(this.url);
    this._socket.addEventListener('close', () => {
      this._socket.close();
      if (!this.isConnected && !this._isDisconnecting) console.log(this.url + ' is not reachable');
      else console.log(`Disconnected from radar socket`);

      // only re-connect if not manually disconnected
      if (this.isConnected && !this._isDisconnecting) setTimeout(() => this.reconnect(), this._connectionTimeout);
      this.isConnected = false;

      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
    this._socket.addEventListener('open', () => {
      console.log(`Connected to radar socket`);
      this.isConnected = true;

      this._keepAlive = setInterval(() => {
        if (this._socket.readyState == WebSocket.OPEN) this.send('ping');
      }, 30000);
    });
    this._socket.addEventListener('message', (event) => {
      this.onMessage(event);
    });
    this._socket.addEventListener('error', (event) => {
      console.error(`Error on radar socket: ` + JSON.stringify(event));

      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
  }

  public send(message: string) {
    this._socket.send(message);
  }

  public disconnect(): any {
    console.log('Radar socket service is disconnecting!');
    this.isConnected = false;
    this._isDisconnecting = true;
    if (this._socket != null) this._socket.close();
  }

  private onMessage(event: MessageEvent) {
    if (event.data === undefined) return;
    const message = JSON.parse(event.data);

    switch (message.route) {
      case 'image':
        this.ImageChanged.next(message.body);
        break;
    }
  }
}
