import { effect, EventEmitter, Injectable, signal, WritableSignal } from '@angular/core';
import { VesselToFollow } from '../app/events/events-detail/vessel-tofollow.class';

export class AisReplaySettings {
  public readonly settingsKey = 'ais-replay.settings';

  public speedMultiplier = signal(1); // Default speed is 1x
  public enableSmoothPlayback = signal(true);
  public followVessel = signal(false);
  public showTooltipSource = signal(true);
  public showTooltipTarget = signal(true);

  public readonly speedMultiplierKey = `${this.settingsKey}.speedMultiplier`;
  public readonly enableSmoothPlaybackKey = `${this.settingsKey}.enableSmoothPlayback`;
  public readonly followVesselKey = `${this.settingsKey}.followVessel`;
  public readonly showTooltipSourceKey = `${this.settingsKey}.showTooltip.source`;
  public readonly showTooltipTargetKey = `${this.settingsKey}.showTooltip.target`;
}

@Injectable()
export class AisReplayService {
  //implements OnDestroy {
  private timeIncrement = 1000; // 1 second increment
  private playbackSpeed = 100; // Milliseconds between intervals

  public minTime: number;
  public maxTime: number;

  public readonly speedMultiplierProfiles = [1, 5, 10, 20, 100];

  //TODO: This will need to be saved the the localstorage when any of the values change
  public settings: AisReplaySettings = new AisReplaySettings(); // Default speed is 1x

  public isFromEvent = false;

  public currentTime = signal(0);
  public isPlaying = signal(false);

  public vessels: WritableSignal<VesselToFollow[]> = signal([]);

  public replayStarted = new EventEmitter();
  public replayPaused = new EventEmitter();
  public replayStopped = new EventEmitter();

  private playbackInterval: any;

  public constructor() {
    //TODO: Get the settings from the localstorage!
    const speedMultiplier = localStorage.getItem(this.settings.speedMultiplierKey);
    if (speedMultiplier) this.settings.speedMultiplier.set(+speedMultiplier);

    const enableSmoothPlayback = localStorage.getItem(this.settings.enableSmoothPlaybackKey);
    if (enableSmoothPlayback) this.settings.enableSmoothPlayback.set(enableSmoothPlayback === '1');

    const followVessel = localStorage.getItem(this.settings.followVesselKey);
    if (followVessel) this.settings.followVessel.set(followVessel === '1');

    const showTooltipSource = localStorage.getItem(this.settings.showTooltipSourceKey);
    if (showTooltipSource) this.settings.showTooltipSource.set(showTooltipSource === '1');

    const showTooltipTarget = localStorage.getItem(this.settings.showTooltipTargetKey);
    if (showTooltipTarget) this.settings.showTooltipTarget.set(showTooltipTarget === '1');

    effect(() => {
      localStorage.setItem(this.settings.speedMultiplierKey, '' + this.settings.speedMultiplier());
    });
    effect(() => {
      localStorage.setItem(this.settings.enableSmoothPlaybackKey, this.settings.enableSmoothPlayback() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.followVesselKey, this.settings.followVessel() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.showTooltipSourceKey, this.settings.showTooltipSource() ? '1' : '0');
    });
    effect(() => {
      localStorage.setItem(this.settings.showTooltipTargetKey, this.settings.showTooltipTarget() ? '1' : '0');
    });
  }

  public setVessels(vessels: VesselToFollow[]): void {
    this.vessels.set(vessels);
    this.setupPlayback();
  }

  private setupPlayback() {
    const { minTime, maxTime } = this.getGlobalTimeBounds();
    this.minTime = minTime;
    this.maxTime = maxTime;

    //Will defer the value update until the angulars change detection cycle completes --> If you remove this, an error will be shown in the logs
    setTimeout(() => {
      this.currentTime.set(minTime);
    });
  }

  private getGlobalTimeBounds() {
    let minTime = Infinity;
    let maxTime = -Infinity;

    this.vessels().forEach((vessel) => {
      vessel.histories.forEach((point) => {
        const time = new Date(point.time!).getTime();
        if (time < minTime) minTime = time;
        if (time > maxTime) maxTime = time;
      });
    });

    return { minTime, maxTime };
  }

  // public ngOnDestroy(): void {
  //   //TOOD: Implement!
  // }

  public playPausePlayback() {
    if (this.isPlaying()) {
      this.pausePlayback();
      this.replayPaused.emit();
    } else {
      this.startPlayback();
    }
  }

  private pausePlayback() {
    clearInterval(this.playbackInterval);
    this.isPlaying.set(false);
  }

  public stopPlayback() {
    this.pausePlayback();
    this.currentTime.set(this.minTime);

    this.replayStopped.emit();
  }

  private startPlayback() {
    this.isPlaying.set(true);

    this.replayStarted.emit();

    this.playbackInterval = setInterval(() => {
      if (this.currentTime() > this.maxTime) {
        this.stopPlayback();
        return;
      }

      this.currentTime.update((currentTime) => currentTime + this.timeIncrement * this.settings.speedMultiplier());
    }, this.playbackSpeed);
  }
}
