import { Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, ViewChild } from '@angular/core';
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, tap, switchMap, of, map, catchError } from 'rxjs';
import { AssetClient, ResData } from '../../../services/apis/cloud.service';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-vessel-search',
  standalone: true,
  imports: [FleetManagerComponents, NgbTypeaheadModule],
  templateUrl: './vessel-search.component.html',
  styleUrl: './vessel-search.component.scss',

  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          width: '44px', // Icon width only
        })
      ),
      state(
        'expanded',
        style({
          width: '280px', // Full width when expanded
        })
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class VesselSearchComponent {
  @ViewChild('searchInput') searchInput!: ElementRef;

  public vesselQuery = '';
  public searchingQuery = false;
  public searchFormatter = (info: ResData) => (info ? `${info.name} - ${info.id}` : '');

  public expanded: boolean = false;

  private readonly assetClient = inject(AssetClient);

  @Input() public usePlaceholder = true;
  @Input() public useSmallDropdown = false;
  @Input() public noBorder = false;
  @Input() public collapseToStart = false;

  @Input() public typeAheadPlacement: 'bottom-end' | 'bottom-start' = 'bottom-start';

  private _selectedItem: ResData | undefined;
  public get selectedItem(): ResData | undefined {
    return this._selectedItem;
  }
  @Input()
  public set selectedItem(selectedItem: ResData | undefined) {
    this._selectedItem = selectedItem;
    if (!this._selectedItem) {
      this.vesselQuery = '';
    }
  }

  @Output() public selectedItemChange = new EventEmitter<ResData | undefined>();

  public searchName: OperatorFunction<string, readonly ResData[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.searchingQuery = true)),
      switchMap((term) => {
        if (term.length < 3) return of([]);

        return this.assetClient.getInfo(term).pipe(
          map((infos) => infos.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.searchingQuery = false))
    );

  // https://github.com/ng-bootstrap/ng-bootstrap/issues/1119#issuecomment-621156541
  scroll($event: any) {
    const elem = $event.currentTarget.nextElementSibling.getElementsByClassName('active')[0];
    elem.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  public toggleExpand(event: Event): void {
    event.stopPropagation(); // Prevents triggering global click events

    this.expandInput(!this.expanded);
  }

  private expandInput(collapse: boolean) {
    this.expanded = collapse;

    setTimeout(() => {
      if (this.expanded) this.searchInput.nativeElement.focus();
      else {
        this.searchInput.nativeElement.blur();
        this.vesselQuery = '';
      }
    }, 0);
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(target: HTMLElement): void {
    if (!target.closest('.mmsi-query')) {
      this.expandInput(false);
    }
  }

  public onSelectedItemChanged(selectedItem: ResData | undefined) {
    this.selectedItem = selectedItem;
    this.selectedItemChange.emit(selectedItem);
  }

  onVesselQueryChanged(value: string) {
    if (!value) {
      // Input is cleared
      this.onSelectedItemChanged(undefined);
    }
  }
}
