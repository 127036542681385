@if(selectedMapTrackerData){
<app-map-item-popup [coordinates]="[selectedMapTrackerData.lat, selectedMapTrackerData.lng]">
  <div header class="map-object--header">
    {{ selectedMapTrackerData.name ?? "" }}
    <small class="map-object--header-small">({{ selectedMapTrackerData.mmsi }})</small>
  </div>

  <div footer>
    <svg
      [class.time-delay-ok]="timeDifferenceInMinutes < 2"
      [class.time-delay-warning]="timeDifferenceInMinutes >= 2 && timeDifferenceInMinutes < 5"
      [class.time-delay-danger]="timeDifferenceInMinutes >= 5"
      class="pe-none me-1 bi bi-center"
      width="15"
      height="15"
    >
      <use href="bootstrap-icons.svg#circle-fill" />
    </svg>
    Last Update: {{ timeDifference }}
  </div>

  <div class="map-object--content">
    <div class="text-start mt-1">
      <b>IMO:</b>
      {{ selectedMapTrackerData.imoNumber ?? "-" }}
    </div>

    @if(selectedMapTrackerData.eniNumber){
    <div class="text-start mt-1">
      <b>ENI:</b>
      {{ selectedMapTrackerData.eniNumber }}
    </div>

    } @if(selectedMapTrackerData.sourceType){
    <div class="d-flex w-100">
      <div class="">
        <b>Type:</b>
        {{
          selectedMapTrackerData.sourceType.name ??
            (selectedMapTrackerData!.obj?.meta && selectedMapTrackerData!.obj!.meta!["SHIP_TYPE"] ? "Other" : "Unknown")
        }}
        @if(selectedMapTrackerData!.obj?.meta && selectedMapTrackerData!.obj!.meta!["SHIP_TYPE"]){ ({{
          this.selectedMapTrackerData!.obj!.meta!["SHIP_TYPE"]
        }})}
      </div>

      <ngx-colors
        ngbTooltip="Change shiptype color"
        class="ms-auto"
        [hideColorPicker]="true"
        [hideTextInput]="true"
        ngx-colors-trigger
        [(ngModel)]="selectedMapTrackerData.sourceType.color"
        (ngModelChange)="sourceTypeColorChanged($event)"
      ></ngx-colors>
    </div>
    }

    <div>
      <b>Speed:</b>
      {{ selectedMapTrackerData.speed }} {{ selectedMapTrackerData.obj?.type === "AIS" ? "knots" : "m/s or knots" }}
    </div>
    <div>
      <b>Course:</b>
      {{ selectedMapTrackerData.course }} °
    </div>
    @if(selectedMapTrackerData.heading){
    <div>
      <b>Heading:</b>
      {{ selectedMapTrackerData.heading }} °
    </div>
    }
    <div>
      <b>True heading:</b>
      {{ selectedMapTrackerData.trueHeading }} °
    </div>
    <div class="text-start mt-1">
      <b class="me-1">Nav. status:</b>
      {{ showNavigationStatus(selectedMapTrackerData.obj?.navigation_status) }}
    </div>
  </div>

  <ng-container sideMenu>
    <button
      (click)="centerMapToTracker()"
      class="map-object--menu-item btn fleet-button-popup border-0"
      ngbTooltip="Center to view"
      [openDelay]="300"
      container="body"
    >
      <svg class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#crosshair" />
      </svg>
    </button>
    @if(selectedMapTrackerData.obj?.type === 'AIS'){
    <button
      (click)="mapHistoryService.addObjectToReplay(selectedMapTrackerData.obj!)"
      class="map-object--menu-item btn fleet-button-popup border-0"
      ngbTooltip="View history"
      [openDelay]="300"
      container="body"
    >
      <svg class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#clock-history" />
      </svg>
    </button>
    } @if(selectedMapTrackerData.asset){
    <button
      [routerLink]="['/vessels', selectedMapTrackerData.asset.id!]"
      class="map-object--menu-item btn fleet-button-popup border-0"
      ngbTooltip="Go to asset"
      container="body"
      hoverState
      (isHovering)="assetIconHovering = $event"
    >
      <img
        themedImage
        srcLight="icons/vessel-dark.svg"
        srcDark="icons/vessel-light.svg"
        [isHovering]="assetIconHovering"
        class="bi pe-none"
        width="24"
        height="24"
        alt=""
      />
    </button>
    @if(radarAvailable){
    <button
      (click)="toggleRadarInfo()"
      class="map-object--menu-item btn fleet-button-popup border-0"
      ngbTooltip="View radar"
      [openDelay]="300"
      container="body"
      [class.text-bg-success]="radarActive"
      [class.text-white]="radarActive"
    >
      <svg class="bi pe-none" width="24" height="24">
        <use href="bootstrap-icons.svg#radar" />
      </svg>
    </button>
    } }
  </ng-container>
</app-map-item-popup>
}
