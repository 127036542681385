<div
  class="mmsi-query"
  [class.dropdown-small]="useSmallDropdown"
  [@expandCollapse]="collapseToStart && expanded ? 'expanded' : collapseToStart ? 'collapsed' : null"
  [class.collapsed]="!expanded"
  [class.mmsi-query-noPlaceholder]="!usePlaceholder"
>
  <!-- https://ng-bootstrap.github.io/#/components/typeahead/examples -->
  <label for="exampleFormControlInput1" class="form-label" *ngIf="!usePlaceholder">Search MMSI or name</label>

  <div class="mmsi-query--input">
    <i class="fa icon" (click)="toggleExpand($event)" [ngClass]="searchingQuery ? 'fa-spinner fa-spin' : 'fa-search'"></i>

    <input
      #searchInput
      [(ngModel)]="vesselQuery"
      (ngModelChange)="onVesselQueryChanged($event)"
      name="mmsi-query"
      type="search"
      class="form-control mmsi-query--input-field"
      [class.mmsi-query--input-field-noborder]="noBorder"
      aria-label="Search"
      [ngbTypeahead]="searchName"
      (selectItem)="onSelectedItemChanged($event.item)"
      [appAutoFocusCondition]="!collapseToStart"
      [resultFormatter]="searchFormatter"
      [inputFormatter]="searchFormatter"
      (keyup.arrowDown)="scroll($event)"
      (keyup.arrowUp)="scroll($event)"
      [placeholder]="usePlaceholder ? 'Search MMSI or name' : ''"
      [placement]="typeAheadPlacement"
    />
  </div>
  <!-- @if (searchingQuery) {
  <small class="form-text text-muted mmsi-query-overlay">searching...</small>
  } -->
</div>
