<form class="needs-validation" (ngSubmit)="addAlert()">
  <div class="modal-header">
    <span style="margin-top: 1px" class="me-3">{{ currentStep }}/3</span>
    <h1 class="modal-title fs-5" id="exampleModalLabel">New Alert</h1>
    <button (click)="activeModal.close(false)" type="button" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="currentStep === 1" class="mb-3">
      <p class="fs-5">Setup the condition that triggers the alert</p>
      <label for="alert-name" class="col-form-label fw-semibold">Name</label>
      <input
        [appAutoFocusCondition]="true"
        [(ngModel)]="alert.name"
        type="text"
        class="form-control"
        name="alert-name"
        id="alert-name"
        required=""
        placeholder="Name of alert"
      />

      <label class="col-form-label fw-semibold mt-2">Choose which assets can trigger alert</label>
      <br />
      <div class="btn-group" role="group">
        <input [(ngModel)]="alert.source" value="ALL" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
        <label
          [ngClass]="alert.source === AlertSourceDto.ALL ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
          class="btn btn-outline-secondary"
          for="btnradio3"
        >
          All
        </label>
        <input [(ngModel)]="alert.source" value="OWNED" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
        <label
          [ngClass]="alert.source === AlertSourceDto.OWNED ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
          class="btn btn-outline-secondary"
          for="btnradio1"
        >
          My fleet
        </label>
        <input [(ngModel)]="alert.source" value="FAVORITE" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
        <label
          [ngClass]="alert.source === AlertSourceDto.FAVORITE ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
          class="btn btn-outline-secondary"
          for="btnradio2"
        >
          Watchlist
        </label>
      </div>
    </div>
    <div [style.display]="currentStep === 2 ? 'block' : 'none'">
      <p class="fs-5">Setup the condition that triggers the alert</p>
      <label class="col-form-label fw-semibold">GeoJSON area for boundary crossing</label>
      <br />
      <input #inputFile class="form-control mb-2" type="file" id="formFile" (change)="onFileChange($event)" accept=".geojson" />
      <div id="alertMap" style="height: 200px"></div>
    </div>
    <div *ngIf="currentStep === 3">
      <p class="fs-5">Which type of notification would you like to receive?</p>
      <input [(ngModel)]="alert.type" [value]="AlertTypeDto.IN_APP" type="radio" class="btn-check" name="btnradio" id="btnradio" autocomplete="off" />
      <label
        [ngClass]="alert.type === AlertTypeDto.IN_APP ? 'radioActive' : activeTheme === 'light' ? 'radioInactiveLight' : 'radioInactiveDark'"
        class="btn btn-outline-secondary"
        for="btnradio"
      >
        In app
      </label>
    </div>
  </div>
  <div class="modal-footer">
    @if(currentStep === 2 || currentStep === 3){
    <button (click)="back()" type="button" class="btn btn-outline-secondary">Back</button>
    } @if(currentStep === 1 || currentStep === 2){
    <button
      (click)="continue()"
      type="button"
      class="btn btn-primary"
      [class.btn-primary]="activeTheme === 'light'"
      [class.btn-light]="activeTheme === 'dark'"
    >
      Continue
    </button>
    } @if(currentStep === 3){
    <button type="submit" class="btn" [class.btn-primary]="activeTheme === 'light'" [class.btn-light]="activeTheme === 'dark'">Save</button>
    }
  </div>
</form>
