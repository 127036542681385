import * as L from 'leaflet';

export function setLayerInteractive(layer: L.Layer, interactive: boolean): void {
  if (layer instanceof L.Layer && (layer as any)._path) {
    (layer as any).options.interactive = interactive;
    const path = (layer as any)._path;

    if (interactive) {
      L.DomUtil.addClass(path, 'leaflet-interactive');
    } else {
      L.DomUtil.removeClass(path, 'leaflet-interactive');
    }
  }
}
