<div class="container-fluid">
  <app-component-header
    title="Alerts"
    newButtonText="New Alert"
    (addNewClicked)="openModal()"
    [enableSearching]="false"
    [enableViewToggling]="false"
  ></app-component-header>
  <!-- <div class="card mb-3 border-0 border-bottom rounded-0">
    <div class="card-body">
      <div class="row justify-content-between align-items-center flex-grow-1f">
        <div class="col-md">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-header-title">Alerts</h4>
          </div>
        </div>
        <div class="col-auto">
          <div class="row align-items-sm-center">
            <div class="col-auto">
              <button *ngIf="activeTheme === 'light'" type="button" class="btn btn-primary" (click)="openModal()">
                <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
                  <use href="bootstrap-icons.svg#plus" />
                </svg>
                New Alert
              </button>
              <button *ngIf="activeTheme === 'dark'" type="button" class="btn btn-light" (click)="openModal()">
                <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
                  <use href="bootstrap-icons.svg#plus" />
                </svg>
                New Alert
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <app-loading-list [isLoading]="isLoading"></app-loading-list>

  <ng-container *ngIf="!isLoading">
    <div *ngIf="alerts.length > 0; else noItems" class="col-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 66px" scope="col"></th>
            <th scope="col">Created</th>
            <th scope="col">Name</th>
            <th scope="col">Source</th>
            <th scope="col">Type</th>
            <th style="width: 66px" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let alert of alerts | orderBy : 'created'">
            <th>
              <div class="form-check form-switch">
                <input [(ngModel)]="alert.enabled" class="form-check-input" type="checkbox" role="switch" (change)="updateAlert(alert)" />
              </div>
            </th>
            <td>
              {{ moment.utc(alert.created).local().format("DD/MM/YYYY HH:mm") }}
            </td>
            <td>{{ alert.name }}</td>
            <td>{{ alert.source ?? "" | firstLetterCapital }}</td>
            <td>{{ alert.type ?? "" | firstLetterCapital }}</td>
            <td>
              <div class="dropdown">
                <svg style="cursor: pointer" class="me-1 bi dropdown-toggle" width="15" height="15" data-bs-toggle="dropdown" aria-expanded="false">
                  <use href="bootstrap-icons.svg#three-dots-vertical" />
                </svg>
                <ul class="dropdown-menu">
                  <li>
                    <button type="button" class="dropdown-item text-danger" (click)="removeAlert(alert)">Remove</button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>

<ng-template #noItems>
  <app-empty-list title="There are currently no alerts" addButtonText="New alert" (add)="openModal()"></app-empty-list>
</ng-template>
