<div id="map" [class.map-draw]="mapMeasuringService.measuringActive()">
  @if(!mapHistoryService.replayMode()){ @if(mapService.mousePosition()){
  <div class="map--position">
    <div class="map--position-col">{{ convertToDMS(mapService.mousePosition()?.lat, true) }}</div>
    <div class="map--position-col">{{ convertToDMS(mapService.mousePosition()?.lng, false) }}</div>
  </div>
  }
  <!-- <div class="markerLegend">
    <div>
      <svg style="fill: #78d0e7" width="20" height="20">
        <use href="bootstrap-icons.svg#circle-fill" />
      </svg>
      <span class="ms-1">AIS</span>
    </div>
    <div class="mt-1 mb-1">
      <svg style="fill: #e1234a" width="20" height="20">
        <use href="bootstrap-icons.svg#circle-fill" />
      </svg>
      <span class="ms-1">Radar</span>
    </div>
    <div>
      <svg style="fill: #ec8e2f" width="20" height="20">
        <use href="bootstrap-icons.svg#circle-fill" />
      </svg>
      <span class="ms-1">AYB (GPS)</span>
    </div>
  </div> -->
  <div class="legend">
    <div>
      <img width="40" src="icons/vessel-owned.svg" alt="" />
      My fleet
    </div>
    <div style="margin-top: 5px">
      <img width="40" src="icons/vessel-favorite.svg" alt="" />
      Watchlist
    </div>
  </div>
  }
</div>

<app-map-object (colorChanged)="vesselTypeColorChanged($event)"></app-map-object>
<!-- 
<app-vessel-search
  #vesselSearch
  class="map--search"
  (selectedItemChange)="selectedItem($event)"
  [noBorder]="true"
  [collapseToStart]="true"
  typeAheadPlacement="bottom-end"
></app-vessel-search> -->

@if(mapHistoryService.replayMode() && mapHistoryService.objectsForReplay().length > 0){
<app-map-history></app-map-history>
}

<app-map-options></app-map-options>

@if(mapMeasuringService.measuringActive()){
<app-map-measuring></app-map-measuring>
}
