import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { ModuleTypeDto } from '../../../../services/apis/cloud.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ThemeService } from '../../../../services/theme-service';
import { ModuleExtended } from '../../../../models/module-extended';
import { AssetsDetailModulesTypeditemComponent } from './assets-detail-modules-typeditem/assets-detail-modules-typeditem.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsDetailAddModuleComponent, AssetsDetailAddModuleResult } from '../assets-detail-module-add/assets-detail-module-add.component';
import { AssetExtended } from '../../../../models/asset-extended';
import { ModulesService } from '../../../../services/modules/modules.service';

@UntilDestroy()
@Component({
  selector: 'app-assets-detail-modules',
  standalone: true,
  imports: [FleetManagerComponents, AssetsDetailModulesTypeditemComponent],
  templateUrl: './assets-detail-modules.component.html',
  styleUrl: './assets-detail-modules.component.scss',
})
export class AssetsDetailModulesComponent {
  public readonly modalService = inject(NgbModal);
  public readonly themeService = inject(ThemeService);
  public readonly modulesService = inject(ModulesService);
  
  @Input({ required: true }) public asset: AssetExtended;
  @Input({ required: true }) public selectedModule?: ModuleExtended;

  public modulesVisible = true;

  @Output()
  public selectModule = new EventEmitter<ModuleExtended>();
  @Output()
  public refreshModule = new EventEmitter<ModuleExtended>();

  public ModuleTypeDto = ModuleTypeDto;

  constructor() {
    const vis = localStorage.getItem('modulesVisible');
    if (vis != null) this.modulesVisible = vis == 'true';
  }

  public toggleModules(): void {
    this.modulesVisible = !this.modulesVisible;
    localStorage.setItem('modulesVisible', this.modulesVisible ? 'true' : 'false');
    this.resizeComponents();
  }

  public resizeComponents(): void {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  public openAddOreditModuleModal(module: ModuleExtended | undefined): void {
    const modalRef = this.modalService.open(AssetsDetailAddModuleComponent);
    modalRef.componentInstance.asset = this.asset;
    modalRef.componentInstance.isNew = module === undefined;
    if (module) modalRef.componentInstance.module = { ...module };

    modalRef.closed.subscribe((result: AssetsDetailAddModuleResult | undefined) => {
      if (!result) return;

      if (result.type === 'add') {
        this.asset.modules.push(result.module);
        this.selectModule.emit(result.module);
      }

      if (result.type === 'update') {
        const moduleToRemove = this.asset.modules.find((m) => m.id === result.module.id);
        if (!moduleToRemove) return;

        const index = this.asset!.modules.findIndex((e) => e.id == result.module.id);
        this.asset.modules[index] = result.module;

        this.refreshModule.emit(result.module);
      }

      if (result.type === 'remove') {
        this.asset.modules.splice(
          this.asset.modules.findIndex((e) => e.id == result.module.id),
          1
        );
        this.selectModule.emit(this.asset.modules.length > 0 ? this.asset.modules[0] : undefined);
      }
    });
  }
}
