<div class="container-fluid" *ngIf="asset">
  <div class="card mb-2 border-0 border-bottom rounded-0">
    <div class="card-body ps-0 pt-1 pb-1">
      <div class="row detail-header justify-content-between align-items-center flex-grow-1f">
        <div class="col-md">
          <svg routerLink=".." style="vertical-align: -12px; cursor: pointer" class="bi" width="40" height="40">
            <use href="bootstrap-icons.svg#arrow-left-short" />
          </svg>
          <span class="card-header-title h4">{{ asset.name }}</span>
          <small *ngIf="asset.uuid !== null && asset.uuid !== 0">({{ asset.uuid }})</small>
        </div>
        <div class="col-auto">
          <svg ngbTooltip="Update asset" (click)="openUpdateAsset()" style="vertical-align: -9px; cursor: pointer" class="bi" width="24" height="24">
            <use href="bootstrap-icons.svg#gear" />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid d-flex ps-0 pe-0">
    @if(!assetLoading && asset){
    <app-assets-detail-modules
      [asset]="asset"
      [selectedModule]="selectedModule"
      (selectModule)="selectModule($event)"
      (refreshModule)="refreshModule($event)"
    ></app-assets-detail-modules>
    }
    <div *ngIf="selectedModule && !assetLoading" class="container-fluid pe-0">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        @for (tabDefinition of modulesService.getTabsForModuleType(selectedModule.type, authService.identity?.Session?.Company?.Id); track $index) {
        <li class="nav-item" role="presentation">
          <button
            (click)="selectedTab = tabDefinition.name"
            [class.active]="selectedTab === tabDefinition.name"
            class="nav-link"
            type="button"
            role="tab"
          >
            {{ tabDefinition.title }}
          </button>
        </li>
        }
      </ul>
      <div class="tab-content">
        <div *ngIf="selectedTab === 'submodules'" class="tab-pane fade show active mt-2" role="tabpanel">
          <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
            @for (subModule of modulesService.getModuleDefinitionFromType(selectedModule.type!)?.subModules; track $index) {

            <div class="col">
              <div
                [class.border-primary]="subModule.isEnabled(selectedModule.parsedConfig.subModules) && activeTheme === 'light'"
                [class.border-light]="subModule.isEnabled(selectedModule.parsedConfig.subModules) && activeTheme !== 'light'"
                class="card rounded-3 shadow-sm h-100"
              >
                <div
                  [class.text-bg-primary]="subModule.isEnabled(selectedModule.parsedConfig.subModules) && activeTheme === 'light'"
                  [class.text-bg-light]="subModule.isEnabled(selectedModule.parsedConfig.subModules) && activeTheme !== 'light'"
                  class="card-header py-3"
                >
                  <h4 class="my-0 fw-normal">{{ subModule.title }}</h4>
                </div>

                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                  <ul class="list-unstyled mt-2 mb-4">
                    @for (feature of subModule.features; track $index) {
                    <li>{{ feature }}</li>
                    }
                  </ul>

                  @if(!subModule.isCore){ @if(subModule.isEnabled(selectedModule.parsedConfig.subModules)){
                  <button (click)="removeSubModule(subModule.name)" type="button" class="w-100 btn btn-lg btn-outline-danger">
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Remove</span>
                  </button>
                  }@else {
                  <button
                    *ngIf="!selectedModule.parsedConfig.subModules.vw"
                    [ngClass]="activeTheme === 'light' ? 'btn-outline-primary' : 'btn-outline-light'"
                    (click)="addSubModule(subModule.name)"
                    type="button"
                    class="w-100 btn btn-lg"
                  >
                    <span *ngIf="subModuleLoading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span *ngIf="!subModuleLoading">Add</span>
                  </button>
                  } }
                </div>
              </div>
            </div>
            }
          </div>
        </div>
        <div *ngIf="selectedTab === 'configuration'" class="tab-pane fade show active" role="tabpanel">
          <div
            *ngIf="selectedModule.type === ModuleTypeDto.AYB || selectedModule.type === ModuleTypeDto.RADAR"
            class="row justify-content-between align-items-center"
          >
            <div class="col-md">
              <select [(ngModel)]="selectedModule.selectedConfigFile" class="form-select" aria-label="Default select example">
                <option *ngFor="let file of selectedModule.configFiles" [ngValue]="file">
                  {{ file.name }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <button (click)="generateConfigs()" type="button" class="btn btn-outline-primary">Generate configs from templates</button>
              <button (click)="saveAndPush()" type="button" class="btn btn-outline-primary ms-1 me-1">Save & Push</button>
              <button disabled type="button" class="btn btn-warning">Deploy to asset</button>
            </div>
          </div>
          <div class="mt-2" style="height: calc(100vh - 205px)">
            <ngx-monaco-editor
              style="height: 100%"
              [options]="editorOptions"
              [(ngModel)]="selectedModule.selectedConfigFile.content"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div *ngIf="selectedTab === 'events'" class="tab-pane fade show active" role="tabpanel">
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="width: 66px" scope="col"></th>
                    <th style="width: 200px" scope="col">Date</th>
                    <th scope="col">Camera</th>
                    <th scope="col">Label</th>
                    <th style="width: 66px" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let event of selectedModule.shownEvents">
                    <th>
                      <img
                        (click)="selectedImage = event.image; openEnlargeThumbnailModal()"
                        style="cursor: pointer"
                        [src]="'data:image/jpeg;base64, ' + event.image"
                        height="50"
                        alt=""
                      />
                    </th>
                    <td>
                      {{ moment.unix(event.timestamp).local().format("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td>{{ event.camera }}</td>
                    <td>{{ event.label }}</td>
                    <td>
                      <svg style="cursor: pointer; color: #0d6efd" (click)="openClipModal(event.id)" class="me-1 bi" width="15" height="15">
                        <use href="bootstrap-icons.svg#cloud-download-fill" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === 1 ? 'disabled' : ''"
                    (click)="selectedModule.selectedEventPage = selectedModule.selectedEventPage - 1; updateEventsList(selectedModule)"
                    class="page-item"
                  >
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === entry ? 'active' : ''"
                    (click)="selectedModule.selectedEventPage = entry; updateEventsList(selectedModule)"
                    *ngFor="let entry of pageNumbers"
                    class="page-item"
                  >
                    <a class="page-link">{{ entry }}</a>
                  </li>
                  <li
                    style="cursor: pointer"
                    [ngClass]="selectedModule.selectedEventPage === totalPages ? 'disabled' : ''"
                    (click)="selectedModule.selectedEventPage = selectedModule.selectedEventPage + 1; updateEventsList(selectedModule)"
                    class="page-item"
                  >
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab === 'settings'" class="tab-pane fade show active mt-2" role="tabpanel">
          <div style="width: 400px">
            <div class="mb-3">
              <label for="customRange1" class="form-label">Number of days stored ({{ numberOfDaysStored }})</label>
              <input [(ngModel)]="numberOfDaysStored" min="1" max="30" type="range" class="form-range" id="customRange1" />
            </div>
            <button class="btn btn-primary">Save</button>
          </div>
        </div>
        <div *ngIf="selectedTab === 'livestream'" class="tab-pane fade show active" role="tabpanel">
          <app-module-livestream #detailLivestream [cameraModule]="selectedModule"></app-module-livestream>
        </div>
        @if (selectedTab === 'images' && selectedModule && asset) {
        <div class="mt-2 text-center">
          <app-module-radar [asset]="asset" [radarModule]="selectedModule"></app-module-radar>
        </div>
        }

        @if (selectedTab === 'status' && selectedModule && asset) {
          <div class="mt-2 text-center">
            <app-module-monitor [monitorModule]="selectedModule"></app-module-monitor>
          </div>
          }
      </div>
    </div>

    <div *ngIf="asset.modules && asset.modules.length === 0 && !assetLoading" class="container-fluid text-center">
      <div class="lead mt-5">No modules yet? Click the plus icon on the left menu to add one!</div>
    </div>
    <div class="container-fluid" *ngIf="assetLoading">
      <p class="placeholder-glow">
        <span class="placeholder w-25"></span>
        <br />
        <span class="placeholder w-25"></span>
        <br />
        <span class="placeholder w-75"></span>
        <br />
        <span class="placeholder w-75"></span>
      </p>
    </div>
  </div>
</div>

<div class="modal fade" id="enlargeThumbnailModel" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <img [src]="'data:image/jpeg;base64, ' + selectedImage" alt="" />
    </div>
  </div>
</div>

<div class="modal fade" id="clipModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div style="height: 320px" class="modal-content">
      <!-- <iframe style="width: 100%; height: 100%;" [src]="clipURL"></iframe> -->
    </div>
  </div>
</div>

<div class="modal fade" id="consoleModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Terminal</h1>
        <button (click)="closeConsoleModal()" type="button" class="btn-close" aria-label="Close"></button>
      </div>
      <div style="height: 500px">
        <ng-terminal #term></ng-terminal>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div style="width: 100%">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow" role="status"></div>
      </div>
    </div>
  </div>
</div>
