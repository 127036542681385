<div class="event-player prevent-select" [ngClass]="'event-player-' + direction">
  <div class="event-player--group">
    <div class="event-player--buttons">
      <div class="btn-group me-3">
        <button class="btn btn-outline-primary event-player--button" (click)="aisReplayService.playPausePlayback()">
          <i [class.fa-play]="!aisReplayService.isPlaying()" [class.fa-pause]="aisReplayService.isPlaying()" class="fa-solid"></i>
        </button>
        <button
          class="btn btn-outline-primary event-player--button"
          (click)="aisReplayService.stopPlayback()"
          [disabled]="!aisReplayService.isPlaying()"
        >
          <i class="fa-solid fa-stop"></i>
        </button>

        <div class="btn-group" ngbDropdown>
          <button type="button" class="btn btn-outline-primary event-player--button" ngbDropdownToggle>
            x{{ aisReplayService.settings.speedMultiplier() }}
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            @for (item of aisReplayService.speedMultiplierProfiles; track $index) {
            <button ngbDropdownItem (click)="aisReplayService.settings.speedMultiplier.set(item)">x{{ item }}</button>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="event-player--buttons">
      <div class="btn-group me-2">
        <button
          class="btn btn-outline-primary event-player--button"
          ngbTooltip="Smooth playback"
          [openDelay]="300"
          container="body"
          (click)="aisReplayService.settings.enableSmoothPlayback.set(!aisReplayService.settings.enableSmoothPlayback())"
          [class.btn-success]="aisReplayService.settings.enableSmoothPlayback()"
          [class.text-white]="aisReplayService.settings.enableSmoothPlayback()"
        >
          <i class="fa-solid fa-water"></i>
        </button>
        <button
          class="btn btn-outline-primary event-player--button"
          ngbTooltip="Pin to vessel"
          [openDelay]="300"
          container="body"
          (click)="aisReplayService.settings.followVessel.set(!aisReplayService.settings.followVessel())"
          [class.btn-success]="aisReplayService.settings.followVessel()"
          [class.text-white]="aisReplayService.settings.followVessel()"
        >
          <i class="fa-solid fa-location-crosshairs"></i>
        </button>
        <button
          class="btn btn-outline-primary event-player--button"
          ngbTooltip="Show tooltip target"
          [openDelay]="300"
          container="body"
          (click)="aisReplayService.settings.showTooltipTarget.set(!aisReplayService.settings.showTooltipTarget())"
          [class.btn-success]="aisReplayService.settings.showTooltipTarget()"
          [class.text-white]="aisReplayService.settings.showTooltipTarget()"
        >
          <i [class.fa-bullseye]="aisReplayService.isFromEvent" [class.fa-tag]="!aisReplayService.isFromEvent" class="fa-solid"></i>
        </button>
        @if(aisReplayService.isFromEvent){
        <button
          class="btn btn-outline-primary event-player--button"
          ngbTooltip="Show tooltip source"
          [openDelay]="300"
          container="body"
          (click)="aisReplayService.settings.showTooltipSource.set(!aisReplayService.settings.showTooltipSource())"
          [class.btn-success]="aisReplayService.settings.showTooltipSource()"
          [class.text-white]="aisReplayService.settings.showTooltipSource()"
        >
          <i class="fa-brands fa-sourcetree"></i>
        </button>
        }
      </div>
    </div>
  </div>

  @if(showSlider){
  <div class="event-player--items event-player--range">
    <div class="event-player--item">
      <input
        type="range"
        [min]="aisReplayService.minTime"
        [max]="aisReplayService.maxTime"
        [(ngModel)]="aisReplayService.currentTime"
        [ngModel]="aisReplayService.currentTime()"
        (ngModelChange)="aisReplayService.currentTime.set($event)"
      />
      <div class="event-player--item-time">Current Time: {{ aisReplayService.currentTime() | date : "dd/MM/yy HH:mm:ss" }}</div>
    </div>
  </div>
  }
</div>
