import { Component, effect, OnDestroy, OnInit, ViewChild } from '@angular/core';
import L, { CircleMarker } from 'leaflet';
import { Router } from '@angular/router';
import { ObjectsWebSocket, ObjectsWebSocketService } from '../../services/objects-websocket-service';
import { NotificationService } from '../../services/shared-service';
import { MapService } from '../../services/map.service';
import { FleetManagerComponents } from '../../shared/fleetmanager-components.module';
import {
  GetObjectsResponse,
  ObjectClient,
  SourceTypeColor,
  VirtualWorldObjectsWebSocket,
  VirtualworldNgxObjectsSocketService,
} from '@dotocean/virtualworld-ngx-services';
import { catchError, tap, of, zip, switchMap, interval, finalize } from 'rxjs';
import { AlertClient, AlertDetailedList, AssetClient, AssetList, AssetTypeDto, ResData } from '../../services/apis/cloud.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapPopupService } from '../../services/map-popup.service';
import TrackSymbol from '@arl/leaflet-tracksymbol2';
import { ThemeService } from '../../services/theme-service';
import { MapTrackerData } from '../../models/map-tracker-data';
import { MapRadarService } from '../../services/map-radar.service';
import { MapObjectComponent } from './map-object/map-object.component';
import { VesselSearchComponent } from '../shared/vessel-search/vessel-search.component';
import { ToastService } from '../../services/toast-service';
import { MapHistoryComponent } from './map-history/map-history.component';
import { MapHistoryService } from './services/map-replay-history.service';
import { ObjectsCacheApiService } from '../../services/objects-cache-api-service';
import { MapOptionsService, NavigationStatus, VesselSourceTypeExtended } from './services/map-options.service';
import { MapOptionsComponent } from './map-options/map-options.component';
import { dmsHelper } from '../../helpers/dms-helper';
import { MapMeasuringService } from './services/map-measuring.service';
import { MapMarkerService } from '../../services/map-marker.service';
import { MapMeasuringComponent } from './map-measuring/map-measuring.component';
import { MapLayerService } from '../../services/map-layer.service';

@UntilDestroy()
@Component({
  selector: 'app-map',
  standalone: true,
  imports: [FleetManagerComponents, MapObjectComponent, VesselSearchComponent, MapHistoryComponent, MapOptionsComponent, MapMeasuringComponent],
  providers: [MapService, MapMarkerService, MapHistoryService, MapRadarService, MapOptionsService, MapMeasuringService, MapLayerService],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild(VesselSearchComponent) vesselSearchComponent?: VesselSearchComponent;

  private _vessels: AssetList[] = [];
  private _objects: Record<number, GetObjectsResponse> = {};
  private _markers: Record<number, L.Marker> = {};
  private _boundMarkers: Record<number, TrackSymbol | CircleMarker> = {};
  private _objectsSocket?: ObjectsWebSocket;
  private _boundsSocket?: VirtualWorldObjectsWebSocket;
  public alerts: AlertDetailedList[] = [];

  private disappearedObjects: Record<number, Date> = {};

  private previousReplayMode = false;

  private readonly assetsMapGroup = new L.LayerGroup();

  constructor(
    private readonly alertClient: AlertClient,
    private readonly assetsClient: AssetClient,
    private readonly objectsSocketService: ObjectsWebSocketService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly boundsObjectsService: VirtualworldNgxObjectsSocketService,
    private readonly objectClient: ObjectClient,
    private readonly themeService: ThemeService,
    private readonly toastService: ToastService,
    private readonly objectsCacheApiService: ObjectsCacheApiService,
    public readonly mapService: MapService,
    public readonly mapLayerService: MapLayerService,
    public readonly mapMarkerService: MapMarkerService,
    private readonly mapPopupService: MapPopupService,
    private readonly mapRadarService: MapRadarService,
    public readonly mapHistoryService: MapHistoryService,
    public readonly mapOptionsService: MapOptionsService,
    public readonly mapMeasuringService: MapMeasuringService
  ) {
    effect(() => {
      this.selectedItem(this.mapOptionsService.vesselSearch());
    });
    effect(
      () => {
        const newReplayMode = this.mapHistoryService.replayMode();
        if (newReplayMode !== this.previousReplayMode) {
          this.previousReplayMode = newReplayMode;

          this.mapMarkerService.selectedMapTrackerData.set(undefined);

          if (newReplayMode) {
            //Disconnect sockets
            this.disconnectSockets();
            this._boundMarkers = {};
            this.mapLayerService.objectGroup()?.clearLayers();
            this.mapService.Map?.removeLayer(this.assetsMapGroup);
            return;
          }

          //Reconnect sockets
          this.assetsMapGroup.addTo(this.mapService.Map!);
          this.getVessels();
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const selectedSources = this.mapOptionsService.vesselSourceTypesSelected();
      const selectedNavigationStatusses = this.mapOptionsService.navigationStatusesSelected();
      this.filterMarkers(selectedSources, selectedNavigationStatusses);
    });

    effect(() => {
      if (mapService.mapLoaded()) {
        const savedState = this.loadMapState();
        if (savedState) this.mapService.Map?.setView([savedState.lat, savedState.lng], savedState.zoom);

        this.assetsMapGroup.addTo(this.mapService.Map!);

        //Moved here so we set map stuff only when the map was loaded
        this.mapService.setBounds();
        this.getAlerts();
        this.getVessels();

        this.mapService.subscribeTo_MoveEnd(() => {
          this.saveMapState();
          if (!this.mapHistoryService.replayMode()) {
            this.updateBoundObjectsSocket();
            this.loadObjects$(false).subscribe();
          }
        });

        this.notificationService.assetsChangedEvent$
          .pipe(
            untilDestroyed(this),
            tap(() => this.getVessels())
          )
          .subscribe();

        // Set up a timer that runs every 10 seconds
        interval(10000)
          .pipe(untilDestroyed(this))
          .subscribe(() => this.checkAndRemoveOldObjects());
      }
    });
  }

  public convertToDMS(coordinate: number | undefined, isLat: boolean) {
    if (!coordinate) return '';
    return dmsHelper.convertToDMS(coordinate, isLat);
  }

  public async ngOnInit() {
    if (!this.mapService.Map) await this.mapService.initMap({});
  }

  private loadMapState(): { lat: number; lng: number; zoom: number } | undefined {
    const savedState = localStorage.getItem('mapBounds');
    if (savedState) return JSON.parse(savedState);

    return undefined;
  }

  private saveMapState() {
    this.mapService.setBounds();

    const center = this.mapService.Map?.getCenter();
    const zoom = this.mapService.Map?.getZoom();
    const mapState = {
      lat: center?.lat,
      lng: center?.lng,
      zoom: zoom,
    };

    localStorage.setItem('mapBounds', JSON.stringify(mapState));
  }

  private connectToObjectsSocket(): void {
    if (!this._objectsSocket) {
      this._objectsSocket = this.objectsSocketService.createObjectsSocket(this._vessels.map((e: any) => e.uuid).join(','));
      this._objectsSocket.ObjectNavigationChanged.subscribe((assets) => this.updateAssets(assets));
      this._objectsSocket.connect();
    }
  }

  private updateBoundObjectsSocket(): void {
    const bounds = this.mapService.getBounds();
    if (!this._boundsSocket) {
      this._boundsSocket = this.boundsObjectsService.createObjectsSocket(bounds);
      this._boundsSocket.ObjectNavigationChanged.subscribe((objects) => this.updateObjects(objects));
      this._boundsSocket.ObjectDisappeared.subscribe((objects) => objects.forEach((obj) => this.objectDisappeared(obj.oid)));
      this._boundsSocket.connect();
    } else {
      this.mapService.setBounds();
      this._boundsSocket.setBounds(this.mapService.getBounds());
    }
  }

  private objectDisappeared(key?: number) {
    if (!key) return;

    const marker = this._boundMarkers[key];
    if (marker != null) {
      this.disappearedObjects[key] = new Date();
    }
  }

  private checkAndRemoveOldObjects(): void {
    const now = new Date();

    Object.keys(this.disappearedObjects).forEach((key) => {
      const objectTime = this.disappearedObjects[+key];
      const timeDiff = (now.getTime() - objectTime.getTime()) / 1000; // time difference in seconds

      if (timeDiff > 10) {
        const marker = this._boundMarkers[+key];
        if (marker != null) {
          // Perform the removal as per the comment instructions
          this.mapService.removeLayer(marker);
          this.mapLayerService.objectGroup()?.removeLayer(marker);
          delete this._boundMarkers[+key];
        }
        // Remove from disappearedObjects as well
        delete this.disappearedObjects[+key];
      }
    });
  }

  private loadObjects$(setBoundedObjectSocket = true) {
    const bounds = this.mapService.getBounds();

    return this.objectClient.getAll(bounds.latSW, bounds.lngSW, bounds.latNE, bounds.lngNE, undefined).pipe(
      tap((objects) => {
        for (const key of Object.keys(this._boundMarkers)) {
          if (!objects.find((e) => e.oid?.toString() === key)) this.objectDisappeared(+key);
        }

        this.updateObjects(objects);

        if (setBoundedObjectSocket) this.updateBoundObjectsSocket();
      }),
      catchError((err) => {
        console.log(err);
        return of(0);
      })
    );
  }

  private getAlerts() {
    var geofencingGroup = this.mapLayerService.geofencingGroup();
    if (!this.mapService.Map || !geofencingGroup) return;

    this.alertClient.getDetailedAlerts(true).subscribe((result) => {
      this.alerts = result;

      this.alerts.forEach((alert) => {
        if (!alert.polygon) return;

        const polygonLayer = L.geoJSON(JSON.parse(alert.polygon), {
          style: {
            color: '#E1234A',
            fillColor: '#E1234A',
            fillOpacity: 0.5,
            weight: 3,
            pane: 'overlayPane',
          },
        })
          .bindPopup(this.mapPopupService.getAlertGeofencingPopupHTML(alert))
          .addTo(geofencingGroup!);

        polygonLayer.on('mouseover', () => polygonLayer.openPopup());
        polygonLayer.on('mouseout', () => polygonLayer.closePopup());
        polygonLayer.on('click', () => this.router.navigate(['alerts']));
        polygonLayer.bringToBack();
      });
    });
  }

  private getVessels() {
    Object.keys(this._markers).forEach((key: any) => {
      this.mapService.removeFromClusteredMarkers(this._markers[key]);
      this._markers[key].remove();
      delete this._markers[key];
    });

    this.assetsClient.getAssets(AssetTypeDto.VESSEL).subscribe((result) => {
      this._vessels = result;
      const objects: any = [];

      const vesselGetSingleObjects$ = this._vessels
        .filter((vessel) => vessel.uuid)
        .map((vessel) =>
          this.objectClient.get(vessel.uuid!, undefined).pipe(
            tap((result) => {
              objects.push(result);
            }),
            catchError(() => {
              return of(null); // Return a fallback value or empty observable
            })
          )
        );

      zip(vesselGetSingleObjects$.length > 0 ? vesselGetSingleObjects$ : [of([])])
        .pipe(
          tap(() => {
            //TODO: The getSingleObject.Subscribe is asynchronised, so the objects array will be empty Or will be partially loaded (If you are lucky you already have the objects, but you must be very lucky)
            this.updateAssets(objects);
            this.connectToObjectsSocket();
          }),
          switchMap(() => this.loadObjects$())
        )
        .subscribe();
    });
  }

  private async updateAssets(objects: GetObjectsResponse[]) {
    objects.forEach((obj: GetObjectsResponse) => {
      const ves = this._vessels.find((e) => e.uuid == obj.oid);
      if (!ves) return;

      const marker = this._markers[obj.oid!];
      const color = this.themeService.getColorForVesselType(obj.type!);
      const trackerData = MapTrackerData.fromObjectNavigation(obj, color, ves, this.objectsCacheApiService.getShipTypeToDisplay(obj));

      if (marker == null) {
        if (!this.mapService.Map || !this.assetsMapGroup) return;

        const icon = L.divIcon({
          className: 'markerVessel ' + (ves.owned ? 'markerVessel-owned' : 'markerVessel-favorite'),
          iconAnchor: [23.5, 60],
          iconSize: [46.98, 60],
        });

        this._markers[ves.uuid!] = L.marker([obj.lat!, obj.lng!], { icon: icon });
        this.mapService.addToClusteredMarkers(ves.owned, this._markers[ves.uuid!], this.assetsMapGroup);

        this._markers[ves.uuid!].on('click', (e) => {
          this.selectedMarker(e.latlng.lat, e.latlng.lng, trackerData);
          L.DomEvent.stopPropagation(e);
        });
      } else {
        marker.setLatLng([obj.lat!, obj.lng!]);
        this.mapMarkerService.updateSelectedMapTracker(trackerData);
      }
    });
  }

  private selectedMarker(lat: number, lng: number, trackerData: MapTrackerData) {
    trackerData.lat = lat;
    trackerData.lng = lng;
    this.mapMarkerService.selectedMapTrackerData.set(trackerData);
  }

  private async updateObjects(objects: GetObjectsResponse[]) {
    objects.forEach((obj) => this.updateObject(obj));

    this.mapOptionsService.refreshNavigationStatusses(Object.values(this._objects));
  }

  private updateObject(obj: GetObjectsResponse, autoSelected = false) {
    delete this.disappearedObjects[obj.oid!];
    const objectGroup = this.mapLayerService.objectGroup();
    if (!this.mapService.Map || !objectGroup) return;

    const currentObjectInList = this._objects[obj.oid!];

    // name, w, l and meta are Metadata that might be set only once and not received in the next updateObject events!
    const objectToUse = {
      ...obj,
      name: currentObjectInList?.name ?? obj.name,
      call_sign: currentObjectInList?.call_sign ?? obj.call_sign,
      w: currentObjectInList?.w ?? obj.w,
      l: currentObjectInList?.l ?? obj.l,
      meta: currentObjectInList?.meta && Object.keys(currentObjectInList.meta).length > 0 ? currentObjectInList?.meta : obj.meta,
    };

    this._objects[obj.oid!] = objectToUse;
    const shipTypeForObject = this.objectsCacheApiService.getShipTypeToDisplay(objectToUse);

    if (this.mapOptionsService.vesselSourceTypesSelected().find((s) => s.name === shipTypeForObject?.name) === undefined) {
      return;
    }

    const navStatussesSelected = this.mapOptionsService.navigationStatusesSelected();
    // When we want to show everything: do not filter
    if (
      navStatussesSelected.length !== this.mapOptionsService.relevantNavigationStatuses().length &&
      navStatussesSelected.find((s) => s.code === (objectToUse.navigation_status ?? 15)) === undefined
    ) {
      return;
    }

    const marker = this._boundMarkers[obj.oid!];

    const color = this.themeService.getColorForVesselType(objectToUse.type!);
    const trackerData = MapTrackerData.fromObjectNavigation(
      objectToUse,
      color,
      this._vessels.find((e) => e.uuid == obj.oid),
      shipTypeForObject
    );

    this.mapMarkerService.addTrackerSymbol(marker, trackerData, objectGroup, (ts: TrackSymbol | CircleMarker) => {
      this._boundMarkers[obj.oid!] = ts;
    });

    if (autoSelected) {
      this.selectedMarker(objectToUse.lat!, objectToUse.lng!, trackerData);
      this.mapService.centerViewToCoordinate([objectToUse.lat!, objectToUse.lng!]);
    }
  }

  public selectedItem(item: ResData | undefined) {
    if (!item) return;

    this.objectClient
      .get(+item.id!, undefined)
      .pipe(
        tap((obj) => this.updateObject(obj, true)),
        catchError(() => {
          this.toastService.showError(`Vessel ${item.name} is not within our bounds`);

          return of();
        }),
        finalize(() => {
          if (this.vesselSearchComponent) this.vesselSearchComponent!.selectedItem = undefined;
        })
      )
      .subscribe();
  }

  public vesselTypeColorChanged(stc: SourceTypeColor) {
    Object.values(this._objects).forEach((e) => {
      const marker = this._boundMarkers[e.oid!];

      if (!marker) return;

      const stcFromObject = this.objectsCacheApiService.getShipTypeToDisplay(e);
      if (stcFromObject?.shipType === stc.shipType && stcFromObject?.sourceType === stc.sourceType) {
        marker.setStyle({ fillColor: stc.color });
      }
    });
  }

  public filterMarkers(selectedSourceTypes: VesselSourceTypeExtended[], selectedNavigationStatusses: NavigationStatus[]) {
    Object.values(this._objects).forEach((e) => {
      const marker = this._boundMarkers[e.oid!];

      if (marker) {
        if (selectedNavigationStatusses.find((s) => s.code === (e.navigation_status ?? 15)) === undefined) {
          marker.remove();
          delete this._boundMarkers[e.oid!];

          return;
        }

        const stcFromObject = this.objectsCacheApiService.getShipTypeToDisplay(e);
        if (selectedSourceTypes.find((s) => s.name === stcFromObject?.name) === undefined) {
          marker.remove();
          delete this._boundMarkers[e.oid!];

          return;
        }
      }

      this.updateObject(e);
    });
  }

  ngOnDestroy() {
    this.mapService?.Dispose();

    this.disconnectSockets();
  }

  private disconnectSockets() {
    if (this._objectsSocket) {
      this._objectsSocket.disconnect();
      this._objectsSocket = undefined;
    }
    if (this._boundsSocket) {
      this._boundsSocket.disconnect();
      this._boundsSocket = undefined;
    }
  }
}
