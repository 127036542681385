import { IMqttMessage } from 'ngx-mqtt';
import { MqttService } from '../services/mqtt-service';
import { Inject } from '@angular/core';
import { IRtcServiceOptions } from '../services/webrtc-service';

export class WebRTCConnection {
  private peerConnection: RTCPeerConnection;
  private remoteVideo: HTMLVideoElement | null = null;
  private _waitHandle = false;
  private stream: MediaStream;

  public connectionId: string;
  public videoStarted = false;
  public videoLoading = false;
  public isPTZ = false;
  public presets: any = [];
  public tours: any = [];

  constructor(
    private mqttService: MqttService,
    connectionId: string,
    videoElement: HTMLVideoElement,
    rtcOptions: IRtcServiceOptions
  ) {
    this.videoLoading = true;
    this.connectionId = connectionId;

    this.remoteVideo = videoElement;
    this.remoteVideo.addEventListener('playing', () => {
      this.videoStarted = true;
      this.videoLoading = false;
    });
    this.remoteVideo.addEventListener('ended', () => {
      this.videoStarted = false;
      this.videoLoading = false;
    });

    this.peerConnection = new RTCPeerConnection({
      iceServers: rtcOptions.iceServers,
    });

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) this.sendMqttMessage({ meetingId: this.connectionId, type: 'ice', candidate: event.candidate });
    };

    this.peerConnection.ontrack = (event) => {
      const [stream] = event.streams;
      this.stream = stream;

      this.refreshSrcStream(this.remoteVideo);
    };
  }

  public refreshSrcStream(videoElement: HTMLVideoElement | null): void {
    if (videoElement) videoElement.srcObject = this.stream;
  }

  public handleMqttMessage(message: IMqttMessage): void {
    try {
      const decoder = new TextDecoder('utf-8');
      const decodedString = decoder.decode(message.payload);
      const payload = JSON.parse(decodedString);
      if (payload.role === 'receiver') return;

      switch (payload.type) {
        case 'sdp':
          this.handleSdpMessage(payload.sdp);
          break;
        case 'ice':
          this.handleIceMessage(payload.candidate);
          break;
        default:
          this.handleInfoMessage(payload);
          break;
      }
    } catch (error) {
      console.error('Error handling MQTT message:', error);
    }
  }

  private handleInfoMessage(payload: any) {
    if (payload == null || payload.rostopic == undefined) return;

    switch (true) {
      case payload.rostopic.indexOf('get_preset_tour') > -1:
        let tourObj = JSON.parse(payload.rosmsg);

        if (this.tours.length == 0) this.tours = tourObj.tours;
        else {
          tourObj.tours.forEach((tour: any) => {
            const cacheTour = this.tours.find((e: any) => e.name == tour.name);
            if (cacheTour != null && cacheTour.action != tour.action) cacheTour.action = tour.action;
          });
        }
        break;
      case payload.rostopic.indexOf('get_preset') > -1:
        if (this.presets.length == 0) {
          const presetObj = JSON.parse(payload.rosmsg);
          this.presets = presetObj.presets;
        }
        break;
      case payload.rostopic.indexOf('ptz') > -1:
        this.isPTZ = true;
        // let ptzObj = JSON.parse(payload.rosmsg);
        // this.zoomLevel = ptzObj.ptz.zoom;
        break;
    }
  }

  private async handleSdpMessage(sdp: any): Promise<void> {
    try {
      if (this._waitHandle) return;
      this._waitHandle = true;

      const remoteDesc = new RTCSessionDescription({ type: 'offer', sdp });
      await this.peerConnection.setRemoteDescription(remoteDesc);

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);

      this.sendMqttMessage({
        meetingId: this.connectionId,
        type: 'sdp',
        sdp: this.peerConnection.localDescription?.sdp,
      });

      this._waitHandle = false;
    } catch (error) {
      console.error('Error handling SDP message:', error);
      this._waitHandle = false;
    }
  }

  private async handleIceMessage(candidate: any): Promise<void> {
    try {
      await this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (error) {
      console.error('Error handling ICE message:', error);
    }
  }

  public async sendMqttMessage(payload: any): Promise<void> {
    this.mqttService.publish(this.connectionId, payload);
  }

  public disconnect(): void {
    this.peerConnection?.close();
  }
}
