import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as i1 from '@dotocean/atlantis-ngx-auth';
class MonitorWebSocketService {
  authService;
  _sockets = [];
  url = 'https://api.dotocean.eu/monitorservice';
  constructor(authService) {
    this.authService = authService;
  }
  createSocket(nodeIds) {
    let result = new MonitorWebSocket(this.url, this.authService, nodeIds);
    this._sockets.push(result);
    return result;
  }
  ngOnDestroy() {
    for (const socket of this._sockets) {
      socket.disconnect();
    }
  }
  static ɵfac = function MonitorWebSocketService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MonitorWebSocketService)(i0.ɵɵinject(i1.AtlantisNgxAuthService));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MonitorWebSocketService,
    factory: MonitorWebSocketService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MonitorWebSocketService, [{
    type: Injectable
  }], () => [{
    type: i1.AtlantisNgxAuthService
  }], null);
})();
class MonitorWebSocket {
  rooturl;
  authService;
  nodeIds;
  url;
  isConnected;
  _isDisconnecting;
  _connectionTimeout = 3000;
  _keepAlive;
  ServiceInfoChanged = new Subject();
  DeviceStatusChanged = new Subject();
  UnkownMessage = new Subject();
  _socket;
  constructor(rooturl, authService, nodeIds) {
    this.rooturl = rooturl;
    this.authService = authService;
    this.nodeIds = nodeIds;
  }
  connect() {
    this.reconnect();
  }
  reconnect() {
    if (!this.authService.identity?.Session.SessionToken) {
      setTimeout(() => this.reconnect(), this._connectionTimeout);
      return;
    }
    const rooturlFormat = this.rooturl.endsWith('/') ? this.rooturl : this.rooturl + '/';
    const nodeIdsJoined = this.nodeIds.join(',');
    this.url = rooturlFormat.replace('http', 'ws') + `monitor/socket?nodeids=${encodeURIComponent(nodeIdsJoined)}&session=${encodeURIComponent(this.authService.identity.Session.SessionToken)}`;
    console.log(`Connecting to monitor socket`);
    this._socket = new WebSocket(this.url);
    this._socket.addEventListener('close', () => {
      if (!this._socket) return;
      this._socket.close();
      if (!this.isConnected && !this._isDisconnecting) console.log(this.url + ' is not reachable');else console.log(`Disconnected from monitor socket`);
      // only re-connect if not manually disconnected
      if (this.isConnected && !this._isDisconnecting) setTimeout(() => this.reconnect(), this._connectionTimeout);
      this.isConnected = false;
      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
    this._socket.addEventListener('open', () => {
      console.log(`Connected to monitor socket`);
      this.isConnected = true;
      setTimeout(() => this.sendAllStatusses(), 1000);
      this._keepAlive = setInterval(() => {
        if (!this._socket) return;
        if (this._socket.readyState == WebSocket.OPEN) this.sendString('ping');
      }, 30000);
    });
    this._socket.addEventListener('message', event => {
      this.onMessage(event);
    });
    this._socket.addEventListener('error', event => {
      console.error(`Error on monitor socket: ` + JSON.stringify(event));
      if (this._keepAlive != null) {
        clearInterval(this._keepAlive);
        this._keepAlive = null;
      }
    });
  }
  disconnect() {
    console.log('monitor socket service is disconnecting!');
    this.isConnected = false;
    this._isDisconnecting = true;
    if (this._socket) this._socket.close();
  }
  onMessage(event) {
    if (event.data === undefined) return;
    var message = JSON.parse(event.data);
    switch (message.route) {
      case 'monitor/serviceInfo':
        this.ServiceInfoChanged.next(message.body);
        break;
      case 'monitor/deviceStatus':
        this.DeviceStatusChanged.next(message.body);
        break;
      default:
        this.UnkownMessage.next(message.body);
        break;
    }
  }
  setNodes(nodeIds) {
    if (this.isConnected && !this._isDisconnecting) {
      this.nodeIds = nodeIds;
      this.send('update/nodes', {
        nodeIds
      });
    }
  }
  sendAllStatusses() {
    if (this.isConnected && !this._isDisconnecting) {
      this.send('send/nodes', {});
    }
  }
  sendString(message) {
    this._socket?.send(message);
  }
  send(route, body) {
    this._socket?.send(JSON.stringify({
      route: route,
      body: body
    }));
  }
}
var DeviceStatusType;
(function (DeviceStatusType) {
  DeviceStatusType[DeviceStatusType["Normal"] = 0] = "Normal";
  DeviceStatusType[DeviceStatusType["Warn"] = 1] = "Warn";
  DeviceStatusType[DeviceStatusType["Error"] = 2] = "Error";
})(DeviceStatusType || (DeviceStatusType = {}));

/*
 * Public API Surface of monitor-ngx-services
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DeviceStatusType, MonitorWebSocket, MonitorWebSocketService };
