import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, of, switchMap, tap } from 'rxjs';
import { GetObjectResponse, GetObjectsResponse, ObjectClient } from '@dotocean/virtualworld-ngx-services';
import TrackSymbol from '@arl/leaflet-tracksymbol2';
import L, { CircleMarker } from 'leaflet';
import { AssetExtended } from '../../../../../models/asset-extended';
import { MapTrackerData } from '../../../../../models/map-tracker-data';
import { ModuleExtended } from '../../../../../models/module-extended';
import { AssetTypeDto } from '../../../../../services/apis/cloud.service';
import { MAP_LAYER_CONFIG_TOKEN, MapLayerConfig, MapLayerService } from '../../../../../services/map-layer.service';
import { MapMarkerService } from '../../../../../services/map-marker.service';
import { MapRadarService } from '../../../../../services/map-radar.service';
import { MapService } from '../../../../../services/map.service';
import { ObjectsCacheApiService } from '../../../../../services/objects-cache-api-service';
import { ObjectsWebSocket, ObjectsWebSocketService } from '../../../../../services/objects-websocket-service';
import { ThemeService } from '../../../../../services/theme-service';
import { FleetManagerComponents } from '../../../../../shared/fleetmanager-components.module';
import { MapMeasuringService } from '../../../../map/services/map-measuring.service';

@Component({
  selector: 'app-module-radar',
  standalone: true,
  imports: [FleetManagerComponents],
  providers: [
    MapService,
    MapMarkerService,
    MapMeasuringService,
    {
      provide: MAP_LAYER_CONFIG_TOKEN,
      useValue: {
        useStorageAsData: false,
      } as MapLayerConfig,
    },
    MapLayerService,
    MapRadarService,
  ],
  templateUrl: './module-radar.component.html',
  styleUrl: './module-radar.component.scss',
})
export class ModuleRadarComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public radarModule: ModuleExtended;
  @Input({ required: true }) public asset: AssetExtended;

  public vesselAssetObject?: GetObjectResponse;
  private vesselAssetMarkers?: TrackSymbol | CircleMarker;
  private vesselAssetTrackerLine?: L.Polyline;
  private readonly vesselAssetTrackerHistoryCoordinates: [number, number][] = [];
  private vesselAssetSocket?: ObjectsWebSocket;

  private readonly mapService = inject(MapService);
  private readonly radarMapService = inject(MapRadarService);
  private readonly mapMarkerService = inject(MapMarkerService);
  private readonly objectClient = inject(ObjectClient);
  private readonly objectsSocketService = inject(ObjectsWebSocketService);
  private readonly themeService = inject(ThemeService);
  private readonly objectsCacheApiService = inject(ObjectsCacheApiService);

  private readonly radarMapGroup = new L.LayerGroup();

  public ngOnInit(): void {
    this.mapService.initMap({ addObjectOverlay: false, showLayerControl: false, showScale: false, showMeasurementTool: false }, 'radar-map');

    this.mapService.mapLoaded$
      .pipe(
        filter((loaded) => loaded),
        tap(() => {
          this.radarMapService.addRadarForVessel(this.radarModule, true);
        }),
        switchMap(() => {
          //Only Vessels can be shown with a radar! If the asset is not a vessel, we don't need to show the radar
          if (this.asset.type !== AssetTypeDto.VESSEL || !this.asset.uuid) {
            return of({});
          }

          return this.objectClient.get(this.asset.uuid!, undefined).pipe(
            tap((result) => {
              this.vesselAssetObject = result;

              this.radarMapGroup.addTo(this.mapService.Map!);

              this.updateAsset(result);
              this.connectToObjectsSocket();
            })
          );
        })
      )
      .subscribe();
  }

  private connectToObjectsSocket(): void {
    if (!this.vesselAssetSocket) {
      this.vesselAssetSocket = this.objectsSocketService.createObjectsSocket(this.asset.uuid!);
      this.vesselAssetSocket.ObjectNavigationChanged.subscribe((assets) => this.updateAsset(assets ? assets[0] : undefined));
      this.vesselAssetSocket.connect();
    }
  }

  private updateAsset(asset: GetObjectsResponse | undefined) {
    if (!asset?.type || !asset.lat || !asset.lng) return;

    const color = this.themeService.getColorForVesselType(asset.type);
    const trackerData = MapTrackerData.fromObjectNavigation(asset, color, undefined, this.objectsCacheApiService.getShipTypeToDisplay(asset));

    this.mapMarkerService.addTrackerSymbol(this.vesselAssetMarkers, trackerData, this.radarMapGroup, (ts: TrackSymbol | CircleMarker) => {
      this.vesselAssetMarkers = ts;
    });

    this.vesselAssetTrackerHistoryCoordinates.push([asset.lat, asset.lng]);
    if (this.vesselAssetTrackerHistoryCoordinates.length > 200) this.vesselAssetTrackerHistoryCoordinates.shift();

    this.mapMarkerService.addTrackerLine(this.vesselAssetTrackerLine, this.vesselAssetTrackerHistoryCoordinates, (ts: L.Polyline) => {
      this.vesselAssetTrackerLine = ts;
    });
  }

  public ngOnDestroy(): void {
    if (this.vesselAssetSocket) {
      this.vesselAssetSocket.disconnect();
      this.vesselAssetSocket = undefined;
    }

    this.mapService.Map?.removeLayer(this.radarMapGroup);

    if (this.vesselAssetMarkers) {
      //this.mapService.Map?.removeLayer(this.vesselAssetMarkers);
      this.vesselAssetMarkers = undefined;
    }
  }
}
