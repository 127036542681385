import { Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { JoystickManagerOptions } from 'nipplejs';
import * as uuid from 'uuid';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleExtended } from '../../../../../models/module-extended';
import { ThemeService } from '../../../../../services/theme-service';
import { WebrtcService } from '../../../../../services/webrtc-service';
import { FleetManagerComponents } from '../../../../../shared/fleetmanager-components.module';
import { NgxJoystickComponent, JoystickEvent } from '../../../../../shared/ngx-joystick/ngx-joystick.component';
import { MqttService } from '../../../../../services/mqtt-service';

@Component({
  selector: 'app-module-livestream',
  standalone: true,
  imports: [FleetManagerComponents],
  templateUrl: './module-livestream.component.html',
  styleUrl: './module-livestream.component.scss',
})
export class ModuleLivestreamComponent implements OnDestroy {
  public readonly webrtcService = inject(WebrtcService);
  public readonly mqttService = inject(MqttService);
  public readonly sanitizer = inject(DomSanitizer);
  public readonly themeService = inject(ThemeService);

  @ViewChild('joystick') staticJoystick!: NgxJoystickComponent;

  private _cameraModule: ModuleExtended;
  public get cameraModule(): ModuleExtended {
    return this._cameraModule;
  }
  @Input({ required: true })
  public set cameraModule(cameraModule: ModuleExtended) {
    if (this._cameraModule !== undefined) this.stopVideo();

    this._cameraModule = cameraModule;
    this.refresh();
  }

  public joystickOptions: JoystickManagerOptions = {
    mode: 'static',
    position: { bottom: '80px', right: '50%' },
    color: '#86D9F0',
  };

  public activeTheme = 'light';
  public nvrURL: SafeResourceUrl;

  private _sendJoystickTimer: any;
  private _joystickData: any;

  public constructor() {
    this.themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  public refresh() {
    switch (this.cameraModule.parsedConfig.source) {
      case 'WEBRTC':
        this.stopVideo();

        setTimeout(() => {
          if (this.cameraModule.webrtcConnection) {
            const videoElement = document.getElementById('videoElement' + module!.id) as HTMLVideoElement;
            if (videoElement) this.cameraModule.webrtcConnection.refreshSrcStream(videoElement);
          }
        }, 500);
        break;
      case 'NVR':
        this.loadNvrURL();
        break;
    }
  }

  public joystickMove(ev: JoystickEvent): void {
    this._joystickData = {
      pan: ev.data.vector.x / 20,
      tilt: ev.data.vector.y / 20,
    };

    if (this._sendJoystickTimer == null)
      this._sendJoystickTimer = setInterval(() => {
        this.cameraModule.webrtcConnection?.sendMqttMessage({
          rostopic: 'axis_camera/camera/ptz/set_move/relative',
          rosmsg: `{ptz: {pan: ${this._joystickData.pan}, tilt: ${this._joystickData.tilt}, zoom:0.0}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.0}}`,
        });
      }, 200);
  }

  public joystickEnd(): void {
    if (this._sendJoystickTimer != null) {
      clearTimeout(this._sendJoystickTimer);
      this._sendJoystickTimer = null;
    }

    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.0}, ptz_vel: 0.0, tilt: 0.0, zoom:0.0}}',
    });
  }

  public loadNvrURL(): void {
    this.nvrURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.cameraModule.parsedConfig.address}`);
  }

  public startVideo() {
    if (!this.mqttService.isConnected) this.mqttService.connect();

    const id = this.cameraModule.id;
    const name = this.cameraModule.parsedConfig.id;
    this.cameraModule.webRtcGuid = uuid.v4();

    const videoElement = document.getElementById('videoElement' + id) as HTMLVideoElement;

    this.cameraModule.webrtcConnection = this.webrtcService.startConnection(`${name}/${this.cameraModule.webRtcGuid}`, videoElement);
  }
  public stopVideo() {
    if (this.cameraModule.webrtcConnection) {
      this.webrtcService.stopConnection(`${this.cameraModule.parsedConfig.id}/${this.cameraModule.webRtcGuid}`);
      this.cameraModule.webrtcConnection = undefined;
    }
  }

  public zoomIn(): void {
    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: 0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public zoomOut(): void {
    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/set_move/relative',
      rosmsg: '{ptz: {pan: 0.0, tilt: 0.0, zoom: -0.03}, ptz_vel: {pan: 0.0, tilt: 0.0, zoom:0.01}}',
    });
  }

  public goToPreset(preset: string): void {
    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/goto_preset',
      rosmsg: `{data: ${preset}}`,
    });
  }
  public activateTour(tour: string): void {
    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/operate_preset_tour',
      rosmsg: `{name: ${tour}, action: start}`,
    });
  }

  public stopTour(): void {
    this.cameraModule.webrtcConnection?.sendMqttMessage({
      rostopic: 'axis_camera/camera/ptz/stop',
      rosmsg: `{data: stop}`,
    });
  }

  public ngOnDestroy() {
    this.stopVideo();
  }
}
