import { AfterViewInit, Component, computed, OnDestroy, ViewChild } from '@angular/core';
import { WebrtcService } from '../../../services/webrtc-service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { FunctionsUsingCSI, NgTerminal, NgTerminalModule } from 'ng-terminal';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ModalComponent } from '../../modal/modal.component';
import moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfigApiService } from '../../../services/config-api-service';
import { CameraApiService } from '../../../services/camera-api-service';
import { AtlantisNgxAuthService } from '@dotocean/atlantis-ngx-auth';
import { NVRApiService } from '../../../services/nvr-service';
import { MqttService } from '../../../services/mqtt-service';
import { CerebroApiService } from '../../../services/cerebro-api-service';
import { finalize, of, tap } from 'rxjs';
import { ThemeService } from '../../../services/theme-service';
import { AssetClient, Group, ModuleTypeDto } from '../../../services/apis/cloud.service';
import { AssetExtended, fromAsset } from '../../../models/asset-extended';
import { ModuleExtended } from '../../../models/module-extended';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsDetailEditComponent } from './assets-detail-edit/assets-detail-edit.component';
import { AssetsDetailModulesComponent } from './assets-detail-modules/assets-detail-modules.component';
import { ModulesService } from '../../../services/modules/modules.service';
import { ModuleMonitorService } from '../../../services/modules/module-monitor.service';
import { DeviceStatusType } from '@dotocean/monitor-ngx-services';
import { ModuleLivestreamComponent } from './assets-detail-modules/module-livestream/module-livestream.component';
import { ModuleMonitorComponent } from './assets-detail-modules/module-monitor/module-monitor.component';
import { ModuleRadarComponent } from './assets-detail-modules/module-radar/module-radar.component';
// eslint-disable-next-line no-var
declare var bootstrap: any;

@Component({
  selector: 'app-asset-detail',
  standalone: true,
  providers: [WebrtcService],
  imports: [
    FleetManagerComponents,
    NgTerminalModule,
    MonacoEditorModule,
    ModalComponent,
    ModuleRadarComponent,
    ModuleLivestreamComponent,
    ModuleMonitorComponent,
    AssetsDetailModulesComponent,
  ],
  templateUrl: './assets-detail.component.html',
  styleUrl: './assets-detail.component.scss',
})
export class AssetDetailComponent implements AfterViewInit, OnDestroy {
  @ViewChild('term', { static: false }) child?: NgTerminal;
  @ViewChild('detailLivestream') detailLivestream!: ModuleLivestreamComponent;

  public ModuleTypeDto = ModuleTypeDto;

  public asset?: AssetExtended;
  public selectedTab = 'submodules';
  public selectedModule?: ModuleExtended;

  public modulesStatuses = computed(() => {
    const statusMap = this.moduleMonitorService.deviceStatuses();

    return this.asset?.modules
      .filter((m) => m.type === ModuleTypeDto.MONITOR)
      .map((module) => {
        const status = statusMap.get(module?.parsedConfig.guid);
        return {
          ...module,
          online: status?.pc === DeviceStatusType.Normal,
          lastUpdated: !status?.timestamp ? 'N/A' : moment.utc(status?.timestamp).fromNow(),
        };
      });
  });

  public editorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    formatOnPaste: true,
    formatOnType: true,
  };
  public enlargeThumbnailModal: any;
  public loadingModal: any;
  public consoleModal: any;
  public clipModal: any;
  public subModuleLoading = false;
  public groups: Group[] = [];
  public configs: any = [];
  public moment: any = moment;
  public selectedImage = '';
  public selectedEventId = '';
  public totalPages = 0;
  public clipURL: SafeResourceUrl;
  public numberOfDaysStored = 25;
  public assetLoading = false;
  public showConfirm = false;
  readonly prompt = '\n' + FunctionsUsingCSI.cursorColumn(1) + '$ ';

  public activeTheme = 'light';

  constructor(
    private readonly assetsClient: AssetClient,
    private readonly route: ActivatedRoute,
    private readonly configService: ConfigApiService,
    private readonly cameraService: CameraApiService,
    public readonly authService: AtlantisNgxAuthService,
    public readonly nvrService: NVRApiService,
    private readonly sanitizer: DomSanitizer,
    private readonly mqttService: MqttService,
    private readonly webrtcService: WebrtcService,
    private readonly cerebroService: CerebroApiService,
    public readonly themeService: ThemeService,
    private readonly modalService: NgbModal,
    public readonly modulesService: ModulesService,
    private readonly moduleMonitorService: ModuleMonitorService
  ) {
    this.loadAsset();

    this.cerebroService.groups$.pipe(tap((groups) => (this.groups = groups))).subscribe();

    themeService.activeTheme$.subscribe((theme) => {
      this.activeTheme = theme;
    });
  }

  private loadAsset() {
    this.assetLoading = true;

    this.assetsClient
      .getAsset(this.route.snapshot.params['id'])
      .pipe(
        tap((response) => {
          this.asset = fromAsset(response);

          if (this.asset.modules) {
            for (const module of this.asset.modules) {
              if (module.type === ModuleTypeDto.CAMERA) this.getEvents(module);
            }
          }

          this.setAssetsMonitor();

          if (this.asset.modules.length > 0) this.selectModule(this.asset.modules[0]);

          setTimeout(() => {
            const tooltipTriggerList: any = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            [...tooltipTriggerList].forEach((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
          }, 200);
        }),
        finalize(() => (this.assetLoading = false))
      )
      .subscribe();
  }

  private setAssetsMonitor(nodeIds: string[] = []) {
    this.asset?.modules?.forEach((module) => {
      if (module.type !== ModuleTypeDto.MONITOR) return; //TODO: || !this.authService.HasApplicationAccess('MONITOR')
      nodeIds.push(module.parsedConfig.guid);
    });

    this.moduleMonitorService.connectNodeIds(nodeIds);
  }

  private async getConfigs(module: ModuleExtended) {
    if (module.type === ModuleTypeDto.CAMERA) {
      this.editorOptions.language = 'json';

      try {
        const config = JSON.stringify(await this.nvrService.getConfig(module.parsedConfig), null, 2);
        module.selectedConfigFile = { content: config };
      } catch {
        module.selectedConfigFile = { content: '' };
      }
    } else if (module.guid != null) {
      this.editorOptions.language = 'yaml';
      module.configFiles = await this.configService.getConfigFiles(module.guid);

      if (module.configFiles && module.configFiles.length > 0) module.selectedConfigFile = module.configFiles[0];
      else module.selectedConfigFile = { content: '' };
    }
  }

  private getEvents(module: any) {
    if (module.guid != null) {
      module.selectedEventPage = 1;
      this.cameraService.getEvents(module.guid).subscribe((result) => {
        module.events = result;
        module.events.sort((a: any, b: any) => b.timestamp - a.timestamp);
        this.updateEventsList(module);
      });
    }
  }

  public updateEventsList(module: any) {
    module.shownEvents = module.events.slice((module.selectedEventPage - 1) * 5, module.selectedEventPage * 5);
  }

  get pageNumbers(): number[] {
    if (!this.selectedModule) return [0];

    const halfRange = 4;
    this.totalPages = Math.ceil((this.selectedModule.events?.length ?? 1) / 5);
    let start = Math.max(1, this.selectedModule.selectedEventPage - halfRange);
    const end = Math.min(start + 8, this.totalPages);

    if (end === this.totalPages) start = Math.max(1, end - 8);

    const pageNumbers = Array.from({ length: Math.min(9, this.totalPages) }, (_, i) => start + i);
    return pageNumbers;
  }

  public async saveAndPush() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.saveAndPushConfigFiles(this.selectedModule.guid, this.selectedModule.configFiles);

    this.loadingModal.hide();
  }

  public async generateConfigs() {
    //TODO: WHERE TF DOES .guid come from???
    if (!this.selectedModule || !this.selectedModule.guid) return;

    this.loadingModal.show();

    await this.configService.generateConfigsFromTemplates(this.selectedModule.guid, this.selectedModule);
    await this.getConfigs(this.selectedModule);

    this.loadingModal.hide();
  }

  public async openUpdateAsset() {
    const modalRef = this.modalService.open(AssetsDetailEditComponent);
    modalRef.componentInstance.editAsset = { ...this.asset };

    modalRef.closed.subscribe((result: boolean) => {
      if (result) {
        this.loadAsset();
      }
    });
  }

  public async openEnlargeThumbnailModal() {
    this.enlargeThumbnailModal.show();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public openClipModal(eventId: string): void {
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`http://localhost:4200/api/events/${eventId}/clip.mp4`);
    // this.clipURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.selectedModule.parsedConfig.address}/api/events/${eventId}/clip.mp4`);
    // this.clipModal.show();
  }

  public async openConsoleModal() {
    // TODO: connect to selectedmodule through ssh
    if (!this.child) return;

    this.child.underlying?.clear();
    this.child.write(FunctionsUsingCSI.cursorColumn(0) + '$ ');

    setTimeout(() => {
      this.child?.underlying?.focus();
    }, 500);

    this.consoleModal.show();
  }

  public async addSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    this.selectedModule.parsedConfig.subModules[submodule] = true;
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  private updateModule$() {
    if (!this.asset || !this.selectedModule) return of();

    const patchOperations = [
      { op: 'replace', path: '/name', value: this.selectedModule.name },
      { op: 'replace', path: '/config', value: this.selectedModule.config },
    ];

    return this.assetsClient.updateModule(this.asset.id!, this.selectedModule.id!, patchOperations);
  }

  public async removeSubModule(submodule: string) {
    if (!this.selectedModule) return;

    this.subModuleLoading = true;

    delete this.selectedModule.parsedConfig.subModules[submodule];
    this.selectedModule.config = JSON.stringify(this.selectedModule.parsedConfig);

    this.updateModule$().subscribe(() => (this.subModuleLoading = false));
  }

  public refreshModule(module: ModuleExtended) {
    switch (module.type) {
      case ModuleTypeDto.AYB:
        break;
      case ModuleTypeDto.CAMERA:
        if (this.detailLivestream) {
          this.detailLivestream.refresh();
        }
        break;
      case ModuleTypeDto.MONITOR:
        this.setAssetsMonitor([module.parsedConfig.guid]);

        this.moduleMonitorService.connectNodeIds([module.parsedConfig.guid]);
        break;
      // case ModuleTypeDto.RADAR:
      //   if (this.selectedModule!.radarSocket) this.selectedModule!.radarSocket.disconnect();
      //   this.connectToRadar(this.selectedModule);
      //   break;

      //TODO: Reselect radar??
    }
  }

  public selectModule(module: ModuleExtended | undefined): void {
    this.selectedModule = module;

    if (!this.selectedModule?.type) return;

    const moduleDefinition = this.modulesService.getModuleDefinitionFromType(this.selectedModule.type);
    if (!moduleDefinition) return;

    this.selectedTab = moduleDefinition.defaultTab;
  }

  ngAfterViewInit(): void {
    this.enlargeThumbnailModal = new bootstrap.Modal('#enlargeThumbnailModel');
    this.loadingModal = new bootstrap.Modal('#loadingModal');
    this.consoleModal = new bootstrap.Modal('#consoleModal');
    this.clipModal = new bootstrap.Modal('#clipModal');

    if (!this.child) return;

    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      cursorBlink: true,
    });

    this.child.onData().subscribe((input) => {
      if (!this.child) return;

      if (input === '\r') {
        this.child.write(this.prompt);
      } else if (input === '\u007f') {
        if (this.child.underlying!.buffer.active.cursorX > 2) {
          this.child.write('\b \b');
        }
      } else if (input === '\u0003') {
        this.child.write('^C');
        this.child.write(this.prompt);
      } else this.child.write(input);
    });
  }

  public closeEnlargeThumbnailModal(): void {
    this.enlargeThumbnailModal.hide();
  }
  public closeConsoleModal(): void {
    this.consoleModal.hide();
  }
  public closeClipModal(): void {
    this.clipModal.hide();
  }

  ngOnDestroy(): void {
    if (this.mqttService != null && this.mqttService.isConnected) this.mqttService.disconnect();

    this.asset?.modules.forEach((module) => {
      if (module.radarSocket) module.radarSocket.disconnect();
      if (module.webrtcConnection) this.webrtcService.stopConnection(`${module.parsedConfig.id}/${module.webRtcGuid}`);
    });
  }
}
